import { Theme } from '@material-ui/core/styles';

export const dropdownMobileTabStyle = (theme: Theme) =>{ 
    return {
        color: theme.palette.primary.light,
        maxWidth: 200,
        "&:before": {
        borderWidth: 0,
        }
};
}