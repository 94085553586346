import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import LoadingSpinner from '../components/LoadingSpinner';
import EmptyStateMessage from '../pages/EmptyStateMessage';
import {
  CRUDListHeader,
  CRUDListLeftContainer,
  CRUDListLeftContainerRef,
  CRUDListRightContainer,
  CRUDListWrapper,
} from '../pages/CRUDList';
import globalState, {GlobalState} from "../globalState";
import APIResponse from "../model/APIResponse";
import {Entity, Exercise, Grocery, Meal, NutritionValue, Resource} from "myfitworld-model";
import {ApiInterface} from "../api/dataInterfaceFactory";
import ContentFilterValues from "../model/ContentFilter";
import ContentFilter from "../pages/content/ContentFilter";
import useEntityList from "../hooks/useEntityList";
import useOrganization from "../hooks/useOrganization";
import {createStyles, Drawer, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import {ContentFilterOptions} from "../utils/contentFilterOptions";
import ExercisePreviewDialog from "../pages/content/exercise/ExercisePreviewDialog";
import {FixedSizeList as List} from 'react-window';
import {isMFWExercise} from "../utils/isMFWcontent";
import get from "lodash/get";
import { useUserProvider } from '../providers/UserProvider';
import { getNameOfNutritionValue, getImperialUnitForNutritionValues, getMetricUnitForNutritionValues, getMetricValue, getImperialValue } from '../utils/nutritionConversion';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { useIntl } from 'react-intl';
import globalMessages from '../messages';
import entityMessages from './messages';
const drawerWidth = 500;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    drawerPaper: {
      width: drawerWidth,
      padding: theme.spacing(4, 4, 0),
      borderLeft:`1px solid  ${theme.palette.text.secondary}`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(4, 2, 0),
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(3),
    },
    content: {
      flex: '0 1 100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      [theme.breakpoints.down('sm')]: {
        overflowY: 'hidden',
      },
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
  }),
);

const SplitScreen = React.forwardRef((
  {sidebarOpen, handleClose, ListContent, FormContent}: { sidebarOpen: boolean, handleClose: () => void, ListContent: any, FormContent: any}, ref: CRUDListLeftContainerRef) => {

  const classes = useStyles();
  const {formatMessage} = useIntl();
  return (
    <CRUDListWrapper>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <CRUDListLeftContainer ref={ref}>{ListContent}</CRUDListLeftContainer>
      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={sidebarOpen}
        classes={{paper: classes.drawerPaper}}
      >
        <div className={classes.drawerHeader}>
          <Button
            size="small"
            startIcon={<CloseIcon/>}
            onClick={handleClose}
          >
            {formatMessage(globalMessages.close)}
          </Button>
        </div>
        <CRUDListRightContainer>
          {sidebarOpen && FormContent}
        </CRUDListRightContainer>
      </Drawer>
    </CRUDListWrapper>
  )
})

const FullWidth = React.forwardRef((
  {sidebarOpen, ListContent, FormContent}: { sidebarOpen: boolean, ListContent: any, FormContent: any}, ref: CRUDListLeftContainerRef) => (
  <Fragment>
    {!sidebarOpen && <CRUDListWrapper>
      <CRUDListLeftContainer ref={ref}> {ListContent} </CRUDListLeftContainer>
    </CRUDListWrapper>}
    {sidebarOpen && FormContent}
  </Fragment>
));

const anyFilterActive = (contentFilter?: ContentFilterValues, query?: string) => {
  let result = Boolean(query);
  if (contentFilter) {
    Object.keys(contentFilter).forEach(key => {
      // @ts-ignore
      result = result || Boolean(contentFilter[key]);
    });
  }
  return result;
};

export const createNewButtonLabel = (IndexName: string) => {

  if(IndexName === "exercise"){
    return "create_new_exercise"
  } else if (IndexName === "grocery") {
    return "create_new_grocery"
  }  else if (IndexName === "workout") {
    return "create_new_workout"
  } else if (IndexName === "program"){
    return "create_new_program"
  }else if (IndexName === "plan"){
    return "create_new_plan"
  } else if (IndexName === "equipment") {
    return "create_new_equipment"
  } else if (IndexName === "fitness"){
    return "create_new_activity"
  } else if (IndexName === "muscle"){
    return "create_new_muscle_group"
  } else if (IndexName === "goals"){
    return "create_new_goal"
  } else if (IndexName === "motor"){
    return "create_new_motor_skill"
  } else if (IndexName === "sport"){
    return "create_new_sport"
  } else if (IndexName === "health"){
    return "create_new_health_issue"
  } else if (IndexName === "expertise"){
    return "create_new_expertise"
  } else if (IndexName === "meal") {
    return "create_new_meal"
  } else if (IndexName === "tutorial") {
    return "create_tutorial"
  }  else if (IndexName === "durationConfiguration") {
    return "create_new_duration"
  }
    return ""
}

export const createNewButtonLabelNoContent = (IndexName: string) => {

  if(IndexName === "exercise"){
    return "create_and_save_your_first_exercise"
  } else if (IndexName === "workout") {
    return "create_and_save_your_first_workout"
  } else if (IndexName === "program"){
    return "create_and_save_your_first_program"
  } else if (IndexName === "grocery"){
    return "create_and_save_your_first_grocery"
  } else if (IndexName === "meal"){
    return "create_and_save_your_first_meal"
  } else if (IndexName === "plan"){
    return "create_and_save_your_first_plan"
  } else if (IndexName === "tutorial") {
    return "create_and_save_your_first_tutorial"
  }
    return ""
}

function EntityManager<T extends Resource>({
                                             entityName, fullWidthForm, entityCreateHelper, apiInterface,
                                             initialFilterState, ListItemComponent, FormComponent, emptyStateTitle,
                                             loadFullEntityData, contentFilterOptions,
                                             sortKey, IndexName,
                                             listItemHeight, appendFormInfoToURL
                                           }: {
  entityName: string,
  fullWidthForm?: boolean
  entityCreateHelper?: string,
  apiInterface: ApiInterface<T>
  ListItemComponent: React.ElementType,
  FormComponent: React.ElementType,
  initialFilterState?: ContentFilterValues,
  emptyStateTitle: string,
  loadFullEntityData?: boolean,
  sortKey?: string,
  contentFilterOptions?: ContentFilterOptions,
  listItemHeight?: number,
  appendFormInfoToURL?: boolean,
  IndexName?: any
}) {
  const [working, setWorking] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>();
  const [editFormOpenForItem, setEditFormOpenForItem] = useState<T>();
  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [previewMFWExercise, setPreviewMFWExercise] = useState<Exercise | null>(null);
  const [contentFilter, setContentFilter] = useState<ContentFilterValues | undefined>(initialFilterState);
  const [query, setQuery] = useState<string>('');
  const {formatMessage} = useIntl();
  const {theme} = useMfwThemeProvider();
  let {data, loadData, loading} = useEntityList<T>(apiInterface.list, contentFilter, sortKey);
  const {organizationId} = useOrganization();
  const {user} = useUserProvider();

  if (query.length >= 3) {
    data = data.filter(_ => get(_, globalState.getRawState().language? 'title.'+globalState.getRawState().language: 'title.en', '').toUpperCase().indexOf(query.toUpperCase()) >= 0);
  }

  const handleCreateFormOpen = () => {
    setEditFormOpenForItem(undefined);
    setCreateFormOpen(true);
  }

  const closeFormPanel = () => {
    setEditFormOpenForItem(undefined);
    setCreateFormOpen(false);
  };

  const Panel = useMemo(() => fullWidthForm ? FullWidth : SplitScreen, [fullWidthForm]);

  const handleCreate = async (d: T) => {
    preSubmitSetup();
    if((d as unknown as Meal).groceries === undefined && (d as unknown as Grocery).nutritionValues !== undefined){
      const allValues:NutritionValue[] = [];
      for (let i = 0; i < (d as unknown as Grocery).nutritionValues.length; i++){
        const name = getNameOfNutritionValue(i);
        const imperialUnit = getImperialUnitForNutritionValues(name);
        const metricUnit = getMetricUnitForNutritionValues(name);
        const isMetric = name === 'energeticValue' ? user?.energeticValueUnit === 'kJ' : user?.weightUnit === undefined || user.weightUnit === 'mg, g, kg';
        const metricValue = isMetric ? (d as unknown as Grocery).nutritionValues[i].metricValue : getMetricValue((d as unknown as Grocery).nutritionValues[i].imperialValue, metricUnit);
        const imperialValue = isMetric ? getImperialValue((d as unknown as Grocery).nutritionValues[i].metricValue, metricUnit) : (d as unknown as Grocery).nutritionValues[i].imperialValue;
        const one:NutritionValue = {
            name: name,
            metricValue: +metricValue,
            imperialValue: +imperialValue, 
            metricUnit: metricUnit,
            imperialUnit: imperialUnit
        }
        allValues.push(one);
      }
      (d as unknown as Grocery).nutritionValues = allValues;
    }

      const grocery = d as unknown as Grocery;
      if(grocery.additionalNutritionValues !== undefined){
        const addNutritionValues:NutritionValue[] = [];
        for (let i = 0; i < grocery.additionalNutritionValues.length; i++){
          const addNutritionValue = grocery.additionalNutritionValues[i];
          const imperialUnit = getImperialUnitForNutritionValues(addNutritionValue.name);          
          const isMetric = user?.weightUnit === undefined || user.weightUnit === 'mg, g, kg';
          const metricUnit = isMetric ? addNutritionValue.metricUnit : getMetricUnitForNutritionValues(addNutritionValue.name);
          const metricValue = isMetric ? addNutritionValue.metricValue : getMetricValue(addNutritionValue.imperialValue, metricUnit);
          const imperialValue = isMetric ? getImperialValue(addNutritionValue.metricValue, metricUnit) : addNutritionValue.imperialValue;
          const one:NutritionValue = {
              name: addNutritionValue.name,
              metricValue: +metricValue,
              imperialValue: +imperialValue, 
              metricUnit: metricUnit,
              imperialUnit: imperialUnit
          }
          addNutritionValues.push(one);
        }
        (d as unknown as Grocery).additionalNutritionValues = addNutritionValues;

      }

    await apiInterface.create({
      ...d,
      id: '-1',
      organizationId,
      isWhiteLabel: theme.isWhiteLabel,
    })
      .then(() => handleSubmitSuccess())
      .catch(handleSubmitError)
      .finally(postSubmitCleanup);
  }

  const handleUpdate = async (d: T) => {
    preSubmitSetup();
    if((d as unknown as Meal).groceries === undefined && (d as unknown as Grocery).nutritionValues !== undefined){
      const allValues:NutritionValue[] = [];
      for (let i = 0; i < (d as unknown as Grocery).nutritionValues.length; i++){
        const name = getNameOfNutritionValue(i);
        const imperialUnit = getImperialUnitForNutritionValues(name);
        const metricUnit = getMetricUnitForNutritionValues(name);
        const isMetric = name === 'energeticValue' ? user?.energeticValueUnit === 'kJ': user?.weightUnit === undefined || user.weightUnit === 'mg, g, kg';
        const metricValue = isMetric ? (d as unknown as Grocery).nutritionValues[i].metricValue : getMetricValue((d as unknown as Grocery).nutritionValues[i].imperialValue, metricUnit);
        const imperialValue = isMetric ? getImperialValue((d as unknown as Grocery).nutritionValues[i].metricValue, metricUnit) : (d as unknown as Grocery).nutritionValues[i].imperialValue;
        const one:NutritionValue = {
            name: name,
            metricValue: +metricValue,
            imperialValue: +imperialValue, 
            metricUnit: metricUnit,
            imperialUnit: imperialUnit
        }
        allValues.push(one);
      }
      (d as unknown as Grocery).nutritionValues = allValues;
    }
    if((d as unknown as Grocery).additionalNutritionValues !== undefined){
      for (let addNutritionValue of (d as unknown as Grocery).additionalNutritionValues || []){
        addNutritionValue.imperialUnit = 'oz';
        const isMetric = user?.weightUnit === undefined || user.weightUnit === 'mg, g, kg';
        addNutritionValue.metricValue = isMetric ? +addNutritionValue.metricValue : +getMetricValue(addNutritionValue.imperialValue, addNutritionValue.metricUnit);
        addNutritionValue.imperialValue = isMetric ? +getImperialValue(addNutritionValue.metricValue, addNutritionValue.metricUnit) : addNutritionValue.imperialValue;
      }
    }
    await apiInterface.update(d)
      .then(async () => handleSubmitSuccess())
      .catch(handleSubmitError)
      .finally(postSubmitCleanup);
  };

  const preSubmitSetup = () => {
    setWorking(true);
    setFormErrorMessage(undefined);
    globalState.update((state: GlobalState) => {
      state.globalLoadingQueue.push(state.globalLoadingQueue.length + 1);
    });
  };

  const postSubmitCleanup = () => {
    globalState.update((state: GlobalState) => {
      state.globalLoadingQueue.shift();
    });
    loadData();
  };

  const handleSubmitSuccess = () => {
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({message: `${formatMessage(globalMessages.changes_saved)}`, severity: "success"});
    });
    setWorking(false);
    closeFormPanel();
  };

  const handleSubmitError = (res: APIResponse) => {
    setWorking(false);
    setFormErrorMessage(res.errorMessage)
  };

  const selectItem = useCallback(async (entity: T) => {
    if (isMFWExercise(entity as unknown as Entity, entityName, organizationId)) {
      setPreviewMFWExercise(entity as unknown as Exercise);
    } else {
      try {
        if (loadFullEntityData && entity.id) {
          const result = await apiInterface.get(entity.id);
          if (result) {
            setEditFormOpenForItem(result);
          } else {
            setEditFormOpenForItem(entity);
          }
        } else {
          
          setEditFormOpenForItem(entity);
        }
      } finally {
        // Needs explicit form close, do not use handleCreateFormOpen because it will overwrite setEditFormOpenForItem
        setCreateFormOpen(false)
      }
    }
  }, [apiInterface, entityName, loadFullEntityData, organizationId]);

  const isFormOpen = createFormOpen || !!editFormOpenForItem;
  const isFullWidthFormOpen = isFormOpen && fullWidthForm;

  const [accordionOpen, setAccordionOpen] = useState(false);

  const getTitle = () :string => {
    switch(entityName) {
      case "Exercise":
        return formatMessage(entityMessages.exercise_title)
      case "Workout":
        return formatMessage(entityMessages.workout_title);
      case "Program":
        return formatMessage(entityMessages.program_title);
      case "Grocery":
        return formatMessage(entityMessages.grocery);
      case "Meal":
        return formatMessage(entityMessages.meal);
      case "NutritionPlan":
        return formatMessage(entityMessages.nutrition_plan);
      case "Equipment":
        return formatMessage(entityMessages.equipment);
      case "Tutorial":
        return formatMessage(entityMessages.tutorial_videos);
      default:
        return entityName;
    }
  }

  const DataListComponent = useMemo(() => {
    return (
      <DataList 
        listItemHeight={listItemHeight}
        data={data}
        ListItemComponent={ListItemComponent}
        selectItem={selectItem}
        accordionOpen={accordionOpen}
      />
    )
  }, [listItemHeight, data, ListItemComponent, selectItem, accordionOpen])

  return (
    <Fragment>
      <CRUDListHeader
        title={getTitle()}
        ctaLabel={formatMessage((entityMessages as any)[createNewButtonLabel(IndexName)])}
        disabled={(editFormOpenForItem !== undefined) || (!user?.isSuperAdmin && IndexName === 'tutorial')}
        onClick={() => {
          handleCreateFormOpen()
        }}
      />

      {!isFullWidthFormOpen && contentFilter && contentFilterOptions && entityName !== 'Tutorial' &&
      <ContentFilter onFilterChange={setContentFilter} options={contentFilterOptions} query={query} setQuery={setQuery}
                     onAccordionToggle={(value) => setAccordionOpen(value)} entityName={entityName}/>
      }

      {
        !createFormOpen &&
        (data && data.length === 0) &&
        anyFilterActive(contentFilter, query) &&
        !loading &&
        <Typography variant='body2'>{formatMessage(globalMessages.no_items_filter)}</Typography>
      }

      {
        !createFormOpen &&
        (data && data.length === 0) &&
        contentFilter &&
        !loading &&
        entityName !== 'Tutorial' &&
        <EmptyStateMessage
          title={emptyStateTitle}
          callToAction={formatMessage((entityMessages as any)[createNewButtonLabelNoContent(IndexName)])}
          onClick={() => {
            handleCreateFormOpen()
          }}
        />
      }

      {(data === undefined || loading) ? <LoadingSpinner/> :

        <Panel
          sidebarOpen={createFormOpen || !!editFormOpenForItem}
          handleClose={closeFormPanel}
          ListContent={DataListComponent}
          FormContent={
            <FormComponent
              handleClose={closeFormPanel}
              handleCreate={handleCreate}
              handleUpdate={handleUpdate}
              mode={editFormOpenForItem ? 'Edit' : 'Create'} // fix mode when creating/editing in language other than EN
              loading={working}
              formErrorMessage={formErrorMessage}
              defaultState={editFormOpenForItem}
              entityName={entityName}
              entityCreateHelper={entityCreateHelper}
              IndexName={IndexName}
              setDefaultState={setEditFormOpenForItem}
            />
          }
        />
      }
      <ExercisePreviewDialog
        exercise={previewMFWExercise}
        open={previewMFWExercise !== null}
        onClose={() => setPreviewMFWExercise(null)}
      />
    </Fragment>
  );
}


const DataList = ({ListItemComponent, data, listItemHeight, selectItem, accordionOpen}:
                    { ListItemComponent: React.ElementType, data: any, listItemHeight?: number, selectItem: (entity: any) => void, accordionOpen: boolean }) => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [listHeight, setListHeight] = useState<number>(600);
  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let isSubscribed = true;
    const handleResize = () => {
      if (window.innerWidth / window.innerHeight > 1 && isSubscribed) {
        setListHeight(window.innerHeight - window.innerHeight * 0.05);
      }
    }

    isSubscribed&&
    window.addEventListener('resize', handleResize);

    // wait for the paint to be done so we have better offset position
    isSubscribed&&
    setTimeout(handleResize, 400);

    return () => {
      window.removeEventListener('resize', handleResize);
     isSubscribed = false;
    }
  }, [listContainerRef, accordionOpen])

  const handleScroll = ({ scrollOffset } : { scrollOffset: any }) => {
    setScrollPosition(scrollOffset);
  };

  useEffect(() => {
    const storedPosition = localStorage.getItem('scrollPosition');
    
    if (storedPosition !== null) {
      setScrollPosition(Number(storedPosition));
      listContainerRef.current?.scrollTo({
        top: Number(storedPosition),
        behavior: "smooth"
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      localStorage.setItem('scrollPosition', String(scrollPosition));
    };
  }, [scrollPosition]);

  return (
    <List
      outerRef={listContainerRef}
      itemCount={(data || []).length}
      itemData={(data || [])}
      height={listHeight}
      width={'100%'}
      itemSize={listItemHeight || 120}
      onScroll={handleScroll}
      initialScrollOffset={scrollPosition}
    >
      {({index, data, style}) => (
        <ListItemComponent
          key={data[index].id}
          item={data[index]}
          onSelect={() => {
            selectItem(data[index])
          }}
          disabled={false}
          style={style}
        />
      )}
    </List>
  )
}
export default EntityManager;
