import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {Invitation} from "myfitworld-model";
import invitationsApi from "../api/invitationsApi";

type InvitationContextState = {
  invitation?: Invitation | null,
  setInvitation: (invitation: Invitation | null) => void,
}

export const INV_KEY = 'invitation';

const InvitationContext = createContext<InvitationContextState>({
  invitation: undefined, setInvitation: (_) => {
  }
})

export const InvitationProvider = ({children}: PropsWithChildren<any>) => {
  const [invitation, setInvitation] = useState<Invitation | null | undefined>(undefined);

  const storeInvitation = (invitation: Invitation | null) => {
    if (invitation && invitation.id) {
      window.localStorage.setItem(INV_KEY, invitation.id);
    } else {
      window.localStorage.removeItem(INV_KEY);
    }
    setInvitation(invitation ? invitation : null);
  }

  useEffect(() => {
    const invitationCodeInStorage = window.localStorage.getItem(INV_KEY);
    if (invitationCodeInStorage) {
      invitationsApi.getInvitation(invitationCodeInStorage)
        .then(result => {
          if (!result || (result && result.acceptedOn)) {
            console.debug('invitation is accepted, cleaning local storage')
            window.localStorage.removeItem(INV_KEY);
          } else {
            setInvitation(result ? result : null);
          }
        })
    } else {
      setInvitation(null);
    }
  }, [])

  return <InvitationContext.Provider
    value={{invitation, setInvitation: storeInvitation}}>{children}</InvitationContext.Provider>
}

export const useInvitationProvider = () => {
  const context = useContext(InvitationContext);

  if (context === undefined) {
    throw new Error(
      'useInvitationProvider must be used within an InvitationProvider'
    );
  }
  return context;
};
