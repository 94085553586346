import {firestore} from "../firebase";
import {Invitation, UserProgram} from "myfitworld-model";
import {UserInvitationWithCrudInstructions} from "./UserWithCRUDInstructions";
import APIResponse from "../model/APIResponse";
import {sanitizeInput} from "./sanitizeInput";
import {processUserPrograms} from './usersApi';
import {WorkoutDay} from "myfitworld-model/src/firestoreCollectionTypes/User";
import firebase from "firebase";

const invitationCollectionRef = firestore.collection('invitations');
const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const acceptInvitation = async (invitationId: string) => {
  invitationCollectionRef
    .doc(invitationId)
    .update({'acceptedOn': new Date()})
    .then(_ => true)
    .catch(err => {
        console.debug('Error accepting invitation', err);
        return false;
      }
    )
}

export const getInvitation = async (invitationId: string): Promise<Invitation | null> => {
  return new Promise<Invitation | null>(resolve => {
    invitationCollectionRef
      .doc(invitationId)
      .get()
      .then(result => {
        if (result.exists) {
          const data = result.data();
          const invitation = {
            id: result.id,
            ...data,
            createdAt: data?.createdAt && data.createdAt.toDate
                ? data.createdAt.toDate().toJSON() // Timestamp object
                : new Date(data?.createdAt).toJSON(),
            acceptedOn: data?.acceptedOn === null || data?.acceptedOn === undefined || data?.acceptedOn === false ?
              null :
                data?.acceptedOn && data.acceptedOn.toDate
                  ? data.acceptedOn.toDate().toJSON() // Timestamp object
                  : new Date(data?.acceptedOn).toJSON(),
          } as Invitation;
          firestore
            .collection(`invitations/${result.id}/assignedPrograms`)
            .get()
            .then((programs) => {
              if (programs.empty) {
                resolve(invitation);
              } else {
                const program = {
                  ...programs.docs[0].data(),
                  id: programs.docs[0].id
                } as unknown as UserProgram;
                firestore
                  .collection(`invitations/${result.id}/assignedPrograms/${program.id}/assignedWorkouts`)
                  .get()
                  .then((workout) => {
                    program.workouts = workout.docs.map((doc) => {
                      const data = doc.data();
                      return {
                        ...data,
                        id: doc.id,
                        dateTime:
                          data.dateTime && data.dateTime.toDate
                            ? data.dateTime.toDate().toJSON() // Timestamp object
                            : new Date(data.dateTime).toJSON(),
                      } as WorkoutDay;
                    });

                    invitation.programs = [program];
                    resolve(invitation);
                  });
              }
            });
        } else {
          resolve(null);
        }
      })
      .catch(err => {
        console.debug('Error retrieving invitation', err);
        resolve(null)
      })
  })

}

export const deleteInvitation = async (invitationId: string) => {
  invitationCollectionRef
    .doc(invitationId)
    .delete()
    .then(_ => true)
    .catch(err => {
        console.debug('Error deleting invitation', err);
        return false;
      }
    )
}

export const updateInvitation = (data: UserInvitationWithCrudInstructions): Promise<APIResponse> => {
  const { programs, workoutDeletions, programDeletions } = data;
  delete data.programs;
  delete data.workoutDeletions;
  delete data.programDeletions;
  const sanitzedData = sanitizeInput(data);
  // @ts-ignore
  sanitzedData.createdAt = data.createdAt ? firebase.firestore.Timestamp.fromDate(new Date(data.createdAt)) : null;
  // @ts-ignore
  sanitzedData.acceptedOn = data.acceptedOn ? firebase.firestore.Timestamp.fromDate(new Date(data.acceptedOn)) : null;

  return new Promise((resolve, reject) => {
    try {
      firestore
        .collection("/invitations")
        .doc(data.id)
        .set({ ...sanitzedData })
        .then(() => {
          if (programs) {
            Promise.all(processUserPrograms(programs, data.id as string, workoutDeletions || [], programDeletions || [],'invitations'))
            .then(() => {
              resolve({ success: true });
            });
          } else {
            resolve({ success: true });
          }
        });
    } catch (e) {
      console.error(e);
      reject({
        success: false,
        errorMessage: "There was an error saving your data. Check if you are connected to the Internet and try again…",
      });
    }
  });
};

export const getInvitationByEmail = (
  email: string,
  wlOrgId:string
): Promise<Invitation | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('invitations').where('email', '==', email).where('whitelabel_org_id', '==', wlOrgId).get().then((snapshot) => {
      snapshot.empty ? resolve(null) : resolve({id: snapshot.docs[0].id, ...snapshot.docs[0].data()} as Invitation);
    }).catch((err) => {
      reject(null);
    })
  });

  export const sendInvitation = async(data: Invitation, roleClient:boolean, orgId:string): Promise<boolean> => {
    const rSendObj = {
      data: data,
      roleClient:roleClient,
      orgId: orgId
    }
    const token = await firebase.auth().currentUser?.getIdToken();
    const response = await fetch(`${FUNCTIONS_BASE_URL}/invitations/invite`, {
    method: 'POST',
    body: JSON.stringify(rSendObj),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  // if(response.status === 200){return true;}
  // return false;
  return true;
  };

  export const revokeInvitationFunctions = async(invitationId: string, roleClient:boolean, orgId:string): Promise<boolean> => {
    const rSendObj = {
      invitationId: invitationId,
      roleClient:roleClient,
      orgId: orgId
    }
    const token = await firebase.auth().currentUser?.getIdToken();
    const response = await fetch(`${FUNCTIONS_BASE_URL}/invitations/revoke`, {
    method: 'POST',
    body: JSON.stringify(rSendObj),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  // if(response.status === 200){return true;}
  // return false;
  return true;
  };

export const archiveInvitationFunctions = async(invitationId: string, roleClient:boolean, orgId:string, archived:boolean): Promise<boolean> => {
  if(invitationId === "" || orgId=== ""){
    return false;
  }
    const rSendObj = {
      invitationId: invitationId,
      roleClient  : roleClient,
      orgId       : orgId,
      archived    : archived,
    }
    const token = await firebase.auth().currentUser?.getIdToken();
    const response = await fetch(`${FUNCTIONS_BASE_URL}/invitations/archive`, {
    method: 'POST',
    body: JSON.stringify(rSendObj),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  // if(response.status === 200){return true;}
  // return false;
  return true;
  };

  export const getInvitationsWithinAnOrganization = async(orgid:string, trainerId:string, isOrgAdmin:boolean):Promise<any> => {
    const query = firestore
      .collection("invitations")
      .where("organization", "==", orgid)
      .where("role", "==", "Client")
      .where("acceptedOn", "==", null)
    if(!isOrgAdmin){
      query.where("invitedBy" ,"==", trainerId)
    }
    const result = await query
      .get()
      .then((snap)=>{
        return snap.docs.filter(invitation => {
          return invitation;
        });
      })
    return result;
  }


export default {
  acceptInvitation,
  deleteInvitation,
  getInvitation
}
