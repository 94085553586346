import React from "react";
import { Box, Hidden, MenuItem, Select } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { OrganizationUser } from "myfitworld-model";
import Role from "myfitworld-model/dist/enums/Role";
import { Fragment, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import TabPanel from "../../components/TabPanel";
import InvitationsTable from "../../components/invitations/InvitationsTable";
import { dropdownMobileTabStyle } from "../globalPagesStyles";
import MembersTable from "./MembersTable";
import organizationMessages from './messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownMobileTabStyle: dropdownMobileTabStyle(theme),
  }),
);

const MyOrganizationPage = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const admins = [Role.Admin]
  const trainers = [Role.Trainer, Role.AssistantTrainer];
  const adminsAndTrainers = [...admins, ...trainers];
  const tabValues = [
    formatMessage(organizationMessages.admins),
    formatMessage(organizationMessages.trainers),
    formatMessage(organizationMessages.archived_admins),
    formatMessage(organizationMessages.archived_trainers),
    formatMessage(organizationMessages.invitations)
  ];

  return (
    <Fragment>
      {/* for larger screens */}
      <Hidden smDown>
        <Box>
          <Tabs
            centered
            value={selectedTab}
            textColor="primary"
            onChange={(_, value: number) => {
              setSelectedTab(value);
            }}
          >
            {tabValues.map(tabName=>
              <Tab label={tabName}/>
            )}
          </Tabs>
        </Box>
      </Hidden>
      {/* For smaller screens */}
      <Hidden mdUp>
        <Box display="flex" justifyContent="center">
          <Select
              value={selectedTab}
              onChange={(e)=>{setSelectedTab(e.target.value as number)}}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={classes.dropdownMobileTabStyle}
            >
              {
                tabValues.map((tabName, tabIndex)=>
                  <MenuItem value={tabIndex}>
                    {tabName}
                  </MenuItem>
                )
              }
            </Select>
        </Box>
      </Hidden>
      <TabPanel value={selectedTab} index={0}>
        <MembersTable
          selectedTab={selectedTab}
          roles={admins}
          showRole={false}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <MembersTable
          selectedTab={selectedTab}
          roles={trainers}
          showRole={false}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <MembersTable
        selectedTab={selectedTab}
          roles={admins}
          showRole={false}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <MembersTable
        selectedTab={selectedTab}
          roles={trainers}
          showRole={false}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <InvitationsTable
          roles={adminsAndTrainers}
          showRole={true}
          createActionLabel="create_member"
          disableInvitationForm={false}
        />
      </TabPanel>
    </Fragment>
  );
};
export default MyOrganizationPage;
