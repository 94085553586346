import React, {Fragment, useState} from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Badge, IconButton} from "@material-ui/core";
import { Forum } from "@material-ui/icons";
import MessagesDrawer from "./MessagesDrawer";
import { useMessagesContext } from "../../providers/MessagesProvider";
import { useMfwThemeProvider } from "myfitworld-utils";

const useStyles = makeStyles(() =>
  createStyles({
    notification: {
      color: "white",
    },
  })
);
const MessageButton = () => {
  const classes = useStyles();
  const theme = useMfwThemeProvider();
  const [isOpen, setOpen] = useState(false);
  const messagesCtx = useMessagesContext();
  const handleNotifications = () => {
    setOpen((prevState) => !prevState);
  };
  return (
    <Fragment>
      <IconButton onClick={handleNotifications}>
        <Badge  badgeContent={messagesCtx.unreadCount} color="error">
          <Forum style={{color:theme.mfwMuiWebTheme.palette.text.primary}} className={classes.notification}/>
        </Badge>
      </IconButton>
      {isOpen && <MessagesDrawer isOpen={isOpen} onClose={handleNotifications}/>}
    </Fragment>
  );
};

export default MessageButton;
