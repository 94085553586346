import React, { memo, useMemo } from "react";
import { Icon } from '../../components';
import { MealPlan as MealPlanIcon } from '../../icons'
import { useMfwThemeProvider } from "myfitworld-utils";


const MealPlan = () => {
  const fillColor = useMfwThemeProvider().mfwMuiWebTheme.palette.text.primary;

  const styes = useMemo(() => {
    return { stroke: 'none', fill: fillColor }
  }, [fillColor])

  return (
    <Icon icon={MealPlanIcon} style={styes} />
  );
};

export default memo(MealPlan);
