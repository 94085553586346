export const config = {
    htmlHeader : {
        iconSizes : [
            "57x57",
            "60x60",
            "72x72",
            "76x76",
            "114x114",
            "120x120",
            "144x144",
            "152x152",
            "180x180"
        ],
        splashScreenSizes : [
             //  - Portrait
            {size:'1284x2778', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"}, //iPhone 12 Pro Max - Portrait
            {size:'1242x2208', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"}, //iPhone 6 Plus/6S Plus/7 Plus/8 Plus - Portrait
            {size:'1334x750', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"}, //iPhone 6/6S/7/8 - Portrait
            {size:'640x1096', media : "(device-width: 768px) and (device-height: 1024px)  and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"}, //iPhone 5/5S/5C - Portrait
            {size:'2048x2732', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"}, //iPad Pro 12.9-inch - Portrait
            {size:'1536x2048', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"}, //iPad Pro 9.7-inch - Portrait
            {size:'768x1024', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"}, //iPad mini - Portrait

            // - Landscape
            {size:'2778x1284', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"}, //iPhone 12 Pro Max - Landscape
            {size:'2208x1242', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"}, //iPhone 6 Plus/6S Plus/7 Plus/8 Plus - Landscape
            {size:'750x1334', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"}, //iPhone 6/6S/7/8 - Landscape
            {size:'1136x600', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"}, //iPhone 5/5S/5C - Landscape
            {size:'2732x2048', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"}, //iPad Pro 12.9-inch - Landscape
            {size:'2048x1536', media : "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"}, //iPad Pro 9.7-inch - Landscape
            {size:'1024x768', media : "(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"}, //iPad mini - Landscape
        ]
    },
    marketplace:{
        productTypes : [
            {
                collectionName : "programs",
                type: "program"
            },
            {
                collectionName : "workouts",
                type: "workout"
            }
        ]
    },
    links: {
        registration: 'registration',
    },
    locationState :{
        marketplaceProgramsPage : "marketplaceProgramsPage"
    }
}