export const initialContentFilterState = {
  sports: "",
  muscleGroups: "",
  secondaryMuscleGroups: "",
  fitnessActivity: "",
  goals: "",
  motorSkills: "",
  healthIssues: "",
  suitableForHealthIssues: "",
  equipment: "",
  difficultyLevel: '',
  muscleRegime: "",
  archived: false,
  showMFWOriginals: false,
  showORGOriginals: false,
  showPublic: false,
  showPremium: false,
  isLockedContentDisplayed: false,
  timePreparation: "",
  diet: "",
  goodFor: "",
  counterProductiveFor: "",
  mealTypes: "",
  //totalKcal: "",
  micronutrient: "",
  category: "",
  glycemicIndexRange: "",
  glycemicLoadRange: "",
  durationInWeeks:"",
  marketplaceMinPrice: "",
  marketplaceMaxPrice: "",
  sortContent : "",
};

export default initialContentFilterState;
