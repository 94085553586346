import React, {Fragment, useContext} from "react";
import {Button, Typography, Box} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SelectOrganizationField from "../SelectOrganizationField";
import {useOrganizationProvider} from "../../providers/OrganizationProvider";
import UserContext from "../../contexts/UserContext";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import NotificationButton from "../notifications/NotificationButton";
import {getCurrentUserRole} from "../../api/usersApi";
import {Role} from "myfitworld-model";
import MessageButton from "../notifications/MessageButton";
import organizationsMessages from "./messages";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("sm")]: {
      wrapper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        "& div": {
          width: "100%",
        },
      },
    },
  })
);

export default () => {
  const {formatMessage} = useIntl();
  const [{availableOrganizations, selectedOrganization}, selectOrganization] = useOrganizationProvider();
  const userContext = useContext(UserContext);
  const classes = useStyles();

  return (
    <Fragment>
      {userContext.organizationOverride && (
        <div className={classes.wrapper}>
          <Button
            variant="text"
            color="secondary"
            startIcon={<ArrowBackIosIcon/>}
            onClick={() => {
              userContext.setOrganizationOverride(undefined);
            }}
          >
            {formatMessage(organizationsMessages.go_back_to_super_admin_dashboard)}
          </Button>
        </div>
      )}

      {
        ((userContext.user && getCurrentUserRole(userContext.user) !== Role.SuperAdmin) ||
          (userContext.user && getCurrentUserRole(userContext.user) === Role.SuperAdmin && !!userContext.organizationOverride)
        )
        &&
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          mt={2}
        >
          {availableOrganizations && availableOrganizations.length > 1 ? (
            <SelectOrganizationField
              label={formatMessage(organizationsMessages.choose_organization)}
              options={availableOrganizations || []}
              value={selectedOrganization?.id}
              onChange={(item) => {
                item && selectOrganization(item);
              }}
            />
          ) : (
            <Box ml={2}>
              <Typography variant="h5" color="textSecondary" noWrap>{selectedOrganization?.name}</Typography>
            </Box>
          )}
          <div className={classes.notification}>
            <MessageButton/>
            <NotificationButton/>
          </div>
        </Box>
      }
    </Fragment>
  );
};
