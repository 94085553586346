import React, {Fragment, useContext, useEffect} from 'react';
import LanguageContext from "./language/LanguageContext";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import { LocalizedTextFieldProps, formatBulletPoints, formatTabForNumbers } from './helpers/FormatTextFunctions';

const LocalizedTextField = (props: LocalizedTextFieldProps)  => {
  const {language, languages, registerField, deregisterField} = useContext(LanguageContext);
  const {name, required} = props;

  useEffect(() => {
    if(name) {
      registerField(name);
      return () => deregisterField(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[name])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.formatText?.bulletPoint && formatBulletPoints(event);
    props.formatText?.tabForNumbers && formatTabForNumbers(event);
  };
  
  return (
    <Fragment>
      {languages.map(lang => (
        <TextField
          {...props}
          key={`${name}-${lang}`}
          style={lang !== language ? {display: 'none'} : undefined}
          name={`${name}.${lang}` || ''}
          required={required && lang === 'en'}
          InputProps={{
            endAdornment: <InputAdornment position="end" style={{width: '1.2rem'}}>{lang.toLocaleUpperCase()}</InputAdornment>,
          }}
          onChange={props.formatText && handleChange}
        />
      ))}
    </Fragment>
  )
}

export default LocalizedTextField;