import React from 'react';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IntlProvider } from "react-intl";
import { Locale } from "../types/config.types"
import globalState from '../globalState';

type ContextType = {
  locale: Locale;
  setLocale: Dispatch<SetStateAction<Locale>>;
};

const Context = createContext<ContextType>({} as ContextType);

export interface TranslationProviderProps {
  children: ReactNode;
}
const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const [locale, setLocale] = useState<Locale>("en");
  const [messages, setMessages] = useState({});
  const langUser = globalState.useState(s => s.language);

  const initializeMessages = useCallback(
    async (newLocale: Locale) => {
      switch (newLocale) {
        case "en-GB":
          const t1 = await import("../translations/extracted/en-GB.json");
          setMessages(t1.default);
          break;
        case "sr": 
          const t2 = await import("../translations/extracted/sr.json");
          setMessages(t2.default);
          break;
        case "bg":
          const t3 = await import("../translations/extracted/bg.json");
          setMessages(t3.default);
          break;
        case "de":
          const t4 = await import("../translations/extracted/de.json");
          setMessages(t4.default);
          break;
        case "hr":
          const t5 = await import("../translations/extracted/hr.json");
          setMessages(t5.default);
          break;
        case "es":
          const t6 = await import("../translations/extracted/es.json");
          setMessages(t6.default);
          break;
        case "ro":
          const t7 = await import("../translations/extracted/ro.json");
          setMessages(t7.default);
          break;
        case "ru":
          const t8 = await import("../translations/extracted/ru.json");
          setMessages(t8.default);
          break;
        case "pt":
          const t9 = await import("../translations/extracted/pt.json");
          setMessages(t9.default);
          break;
        case "en":
        default:
          const t10 = await import("../translations/extracted/en-US.json");
          setMessages(t10.default);
      }
    },
    [setMessages],
  );

  useEffect(() => {
    initializeMessages(langUser as Locale);
  }, [initializeMessages, locale, langUser]);

  const providedValue = useMemo(() => {
    return {
      locale,
      setLocale,
    };
  }, [locale]);
  return (
    <Context.Provider value={providedValue}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export function useTranslationContext() {
  return useContext(Context);
}
export default TranslationProvider;
