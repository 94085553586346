import React, {PropsWithChildren} from 'react';
import {Button, Drawer, useMediaQuery} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDrawerStyles } from "../theme/useDrawerStyles";
import { useMfwThemeProvider } from 'myfitworld-utils';
import { ArchiveOutlined as ArchiveIcon } from "@material-ui/icons";
import { useIntl } from 'react-intl';
import generalMessages from "../messages"
export interface SideDrawerProperties {
  isOpen: boolean;
  onClose: () => void;
  showArchived: boolean;
  setShowArchived: (show:boolean)=>void;
}

export default function ({children, isOpen, onClose, showArchived, setShowArchived}: PropsWithChildren<SideDrawerProperties>) {
  const {mfwMuiWebTheme} = useMfwThemeProvider();
  const isMobile = useMediaQuery(mfwMuiWebTheme.breakpoints.down('sm'));
  const classes = useDrawerStyles(mfwMuiWebTheme, isMobile);
  const drawerClasses = classes();
  const {formatMessage} = useIntl();

  return (
    <Drawer
      classes={{paper: drawerClasses.drawerPaper}}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      <div className={drawerClasses.drawerHeader}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<CloseIcon/>}
          onClick={_ => onClose()}
        >
          {formatMessage(generalMessages.close)}
        </Button>
        <Button 
          size="small"
          variant="outlined"
          startIcon={<ArchiveIcon/>}
          onClick={()=>{setShowArchived(!showArchived)}}>
            {showArchived ? formatMessage(generalMessages.unarchived) : formatMessage(generalMessages.archived)}
        </Button>
      </div>
      {children}
    </Drawer>
  )
}
