import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider, CircularProgress, makeStyles, Theme} from '@material-ui/core';
import './firebase';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {LocationProvider} from '@reach/router';
import UserProvider from './providers/UserProvider';
import './index.css';
import {InvitationProvider} from "./providers/InvitationProvider";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { createStore, compose } from 'redux';
import allReducers from './redux/reducers/index';
import { Provider } from 'react-redux';
import { NotificationsProvider } from './providers/NotificationsProvider';
import { MessagesProvider } from './providers/MessagesProvider';
import {MfwThemeProvider, useMfwThemeProvider } from 'myfitworld-utils'
import CustomHelmet from './components/CustomHelmet';
import { TranslationProvider } from "./contexts";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://e6efd46554ee43b9affc3892d7837dad@o437157.ingest.sentry.io/5539681",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//   tracesSampleRate: 1.0,
// });

const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

const stripePromise = (process.env.REACT_APP_STRIPE_PUBLIC_KEY && loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)) || null;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(allReducers, composeEnhancers());


const AppTheme = () =>{
  const [getThemeBy, setGetThemeBy] = React.useState({functions_base_url:String(FUNCTIONS_BASE_URL)})

  return <Provider store={store}>  
  <TranslationProvider>
  <LocationProvider>
      <MfwThemeProvider getThemeBy={getThemeBy} >
        <AppInit />
      </MfwThemeProvider>
    </LocationProvider>
  </TranslationProvider>
  </Provider>
}

const useStyles = makeStyles((theme: Theme) => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: "0 auto"
  },
}));

const AppInit = () =>{
  const classes = useStyles();
  const {mfwMuiWebTheme, isLoading, theme} = useMfwThemeProvider()  

  React.useEffect(()=>{
    if(!isLoading){
      document.documentElement.style.setProperty('--primary-color', theme.theme.primary);
      document.documentElement.style.setProperty('--backgroundDark-color', theme.theme.backgroundColor.dark);
      document.documentElement.style.setProperty('--backgroundLight-color', theme.theme.backgroundColor.light);
      document.documentElement.style.setProperty('--backgroundDefault-color', theme.theme.backgroundColor.default);
      document.documentElement.style.setProperty('--backgroundContrast-color', theme.theme.backgroundColor.contrast);
      document.documentElement.style.setProperty('--textPrimary-color', theme.theme.text.primary);
      document.documentElement.style.setProperty('--textSecondary-color', theme.theme.text.secondary);
    }
  },[isLoading, mfwMuiWebTheme]);


  return  (
    <>
      {isLoading ? 
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
        :
        <ThemeProvider theme={mfwMuiWebTheme}>
          <CustomHelmet theme={theme}/>
          <InvitationProvider>
            <UserProvider>
              <NotificationsProvider>
                <MessagesProvider>
                <Elements stripe={stripePromise}>
                  <App/>
                </Elements>
                </MessagesProvider>
              </NotificationsProvider>
            </UserProvider>
          </InvitationProvider>
        </ThemeProvider>
      }
    </>
  )
}

ReactDOM.render(
  <AppTheme/>
  ,
  document.getElementById('root')
);

if (process.env.REACT_APP_ENV === 'production') {
  require('./vendor/hotjar');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
