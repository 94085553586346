import { useCallback} from "react";
import { useMutation } from "../base";
import globalState, { GlobalState } from "../../globalState";
import { updateUserOrganizations } from "../../api/usersApi";
import { useIntl } from "react-intl";
import usersMessages from "./messages";


function useUpdateCopyOkUser() {

  const {formatMessage} = useIntl();
  const updateOrganizationCopyOkUserQuery = useCallback(async ({userId, copyOk}:{userId: string; copyOk: boolean})=>{
    try {
      return updateUserOrganizations({id: userId, copyOk: copyOk} );
    } catch (error) {
      globalState.update((state:GlobalState)=>{
        state.toastQueue.push({
          message: formatMessage(usersMessages.error_updating_CopyOk_User),
          severity:"error"
        })
      }) 
      throw error
    }
  }, []);


  const { status, error, isLoading } = useMutation(updateOrganizationCopyOkUserQuery);
  
  return {
    status,
    error,
    isLoading,
    updateOrganizationCopyOkUserQuery,
  };
}
export default useUpdateCopyOkUser;
