import React, { memo, useMemo } from "react";
import { Icon } from '../../components';
import { Premium as PremiumIcon } from '../../icons'
import { useMfwThemeProvider } from "myfitworld-utils";


const Premium = () => {
  const strokeColor = useMfwThemeProvider().mfwMuiWebTheme.palette.text.primary;

  const styes = useMemo(() => {
    return { stroke: strokeColor, fill: 'none' }
  }, [strokeColor])

  return (
    <Icon icon={PremiumIcon} style={styes} />
  );
};

export default memo(Premium);
