import * as React from 'react';
import classNames from 'classnames';
import './icon.scss';

export const ICON_SIZE = {
  tiny: 12,
  small: 18,
  base: 22,
  medium: 26,
  large: 32,
};

export type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
export type IconSize = keyof typeof ICON_SIZE;

export type IconProps = {
  /** Icon component to be rendered, typically SVG. */
  icon?: IconType;
  /** CSS classes to be applied to this component */
  className?: string;
  /** The size of the Icon */
  size?: IconSize;
  /** The style object used to override one or several CSS properties */
  style?: React.CSSProperties;
};

/**
 * Icon component renders the specified icon in standard size and color.
 */
const Icon = ({ icon, className, size = 'base', style = {} }: IconProps) => {
  const Component = icon;

  if (!Component) return null;

  return <Component
    width={ICON_SIZE[size]}
    height={ICON_SIZE[size]}
    className={classNames('mfw-icon', className)}
    style={style}
  />
};

export default Icon;
