
export const SIX_MONTH_PLAN_DISCOUNT = 16;
export const ANNUALLY_PLAN_DISCOUNT = 33;

export const MONTHLY_PLAN = 'MONTHLY_PLAN';
export const SIX_MONTH_PLAN = 'SIX_MONTH_PLAN';
export const ANNUALLY_PLAN = 'ANNUALLY_PLAN';

export const MONTHLY_PLAN_PRICE = '€3.00';
export const SIX_MONTH_PLAN_PRICE = '€2.50';
export const ANNUALLY_PLAN_PRICE = '€2.00';

export const SUBSCRIPTION_CUSTOMER_CREATED = "customer_created";
export const SUBSCRIPTION_ACTIVE = "active";
export const SUBSCRIPTION_PAYMENT_FAILED = "payment_failed";
export const SUBSCRIPTION_USER_CANCEL = "user_cancel";
export const SUBSCRIPTION_AUTOMATIC_CANCEL = "user_automatic_cancel";
export const SUBSCRIPTION_CREATED_NEW = "created_new_subscription";

export interface Invoice {
  total: number,
  created: number,
  status: string,
  hosted_invoice_url: string
}

export interface UpcomingInvoiceItem {
  description: string,
  quantity: number,
  amount: string
}

export interface UpcomingInvoice {
  currency: string,
  periodStart: number,
  periodEnd: number,
  subtotal: string,
  total: string,
  amount_due: string,
  //tax: string,
  total_discount_amounts?: string,
  discountName?: string,
  invoiceItems: UpcomingInvoiceItem[]
}
