import {useOrganizationProvider} from "../providers/OrganizationProvider";

function useOrganization() {
  const [{selectedOrganization}] = useOrganizationProvider();

  return {
    organizationId: selectedOrganization && selectedOrganization?.id,
    organizationStripeConnectId: selectedOrganization && selectedOrganization.stripeConnect?.stripe_user_id
  };
}

export default useOrganization;
