import React, {useContext, useEffect, useMemo, useState} from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LanguageContext from "./LanguageContext";
import {Badge, Box, Typography, createStyles, makeStyles} from "@material-ui/core";
import {Resource} from "myfitworld-model";
import get from 'lodash/get';
import { FiberManualRecord } from '@material-ui/icons';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { Locale } from "../../types/config.types";
import languageMessages from '../../messages';
import { useIntl } from 'react-intl';

const notTranslated = (value: string | undefined): boolean => !(value && value.length > 0);
const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      display:'flex',
      flexDirection:'row'
    },
    dotStyle:{
      fill: "#f44336 !important",  
    }
  })
);
function LanguageSelect<T extends Resource>({watch}: {watch: (_: Array<string>) => T}) {

  const {language, languages, setLanguage, registeredFields} = useContext(LanguageContext);
  const theme = useMfwThemeProvider();
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const [issues, setIssues] = useState<Record<string, boolean>>(() =>
    languages.reduce((acc, curr) => ({...acc, [curr]: false}), {}) as Record<string, boolean>
  );

  const values = useMemo(() => watch(registeredFields), [registeredFields, watch]);
  
  useEffect(() => {
    // Not performant and a mess, idea is rewrite this to count the issues so we can show them in the indicator
    // const checkByField = registeredFields.map(field => {
    //   return languages.reduce((acc, curr) => ({
    //     ...acc, [curr]: notTranslated(get(values, `${field}.${curr}`))
    //   }), {});
    // });
    //
    // const hasIssues = checkByField.reduce((acc, curr) => {
    //   return languages.reduce((set, lang) => ({...set, [lang]: (acc as Record<Languages, boolean>)[lang] || (curr as  Record<Languages, boolean>)[lang] }), {})
    // }, {});
    //
    // setIssues(hasIssues as Record<Languages, boolean>);

    const issues = {} as Record<string, boolean>;
    const enIndex = languages.indexOf("en");
    if(enIndex !== 0){
      const temp = languages[0];
      languages[enIndex] = temp;
      languages[0] = "en";
    }

    languages.forEach(lang => {
      for(let field of registeredFields) { 
        issues[lang] = notTranslated(get(values, `${field}.${lang}`));
        if(issues[lang]) {
          break;
        }
      }
    })

    setIssues(issues as Record<string, boolean>);

  }, [values, registeredFields, languages]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, lang: string | Locale) => {
    if (lang) {
      setLanguage(lang);
    }
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Box className={classes.boxContainer}>
        <FiberManualRecord className={classes.dotStyle} style={{ width: '14px' }} />
        <Typography variant="h6" color="error">{formatMessage(languageMessages.mandatory_field)}</Typography>
      </Box>
      <Box>
        <ToggleButtonGroup
          size="small"
          value={language}
          exclusive
          onChange={handleChange}
          aria-label="Language select"
        >
          {languages.map(lang => (
            <ToggleButton value={lang} aria-label={lang} key={lang}>
              <Badge variant="dot" color="error" invisible={lang !== "en"}>
                <label>{lang}</label>
              </Badge>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
}

export default LanguageSelect;
