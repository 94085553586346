import React, { memo, useMemo } from "react";
import { Icon } from '../../components';
import { Marketplace as MarketplaceIcon } from '../../icons'
import { useMfwThemeProvider } from "myfitworld-utils";


const Marketplace = () => {
  const strokeColor = useMfwThemeProvider().mfwMuiWebTheme.palette.text.primary;

  const styes = useMemo(() => {
    return { stroke: strokeColor, fill: strokeColor }
  }, [strokeColor])

  return (
    <Icon icon={MarketplaceIcon} style={styes} />
  );
};

export default memo(Marketplace);
