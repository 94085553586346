import React, {Fragment} from 'react';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Controller} from 'react-hook-form';
import EntityForm, {EntityFormProps} from "../../../entity/EntityForm";
import {Equipment} from "myfitworld-model";
import LocalizedTextField from "../../../components/LocalizedTextField";
import useOrganization from "../../../hooks/useOrganization";
import { useIntl } from 'react-intl';
import globalMessages from '../../../messages';
import equipmentMessages from './messages';


const EquipmentForm = (props: EntityFormProps<Equipment>) => {
  const { organizationId } = useOrganization();
  const disableForm = props.defaultState?.id ? props.defaultState?.organizationId !== organizationId : false;
  const {formatMessage} = useIntl();
  return (
    <EntityForm<Equipment> {...props} disabled={disableForm}>
      {
        ({register, control}) => (
          <Fragment>
            <LocalizedTextField
              name='name'
              inputRef={register}
              margin='normal'
              label={formatMessage(globalMessages.name)}
              type='text'
              autoFocus
              fullWidth
              required
              disabled={disableForm}
            />
            <Grid container direction="column">
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_distance)}
                control={
                  <Controller
                    name="distance"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="distance" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                    
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_floors)}
                control={
                  <Controller
                    name="floors"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="floors" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_incline)}
                control={
                  <Controller
                    name="incline"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="incline" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_speed)}
                control={
                  <Controller
                    name="speed"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="speed" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_weight)}
                control={
                  <Controller
                    name="weight"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="weight" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_rpm)}
                control={
                  <Controller
                    name="rpm"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="rpm" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_pace)}
                control={
                  <Controller
                    name="pace"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="pace" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_power)}
                control={
                  <Controller
                    name="power"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="power" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
              <FormControlLabel
                label={formatMessage(equipmentMessages.configurable_level)}
                control={
                  <Controller
                    name="level"
                    control={control}
                    render={({onChange, value}) => {
                      return <Switch name="level" disabled={disableForm} color="primary" checked={value} onChange={(_, checked) => onChange(checked)}/>
                    }}
                    disabled={disableForm}
                  />}
              />
            </Grid>
          </Fragment>
        )
      }
    </EntityForm>
  );
};


export default EquipmentForm;
