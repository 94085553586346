import React from 'react';
import { useStoreState } from 'pullstate';
import { default as MuiSnackbar } from '@material-ui/core/Snackbar';
import {Alert} from '@material-ui/lab';
import globalState, { Toast } from '../../globalState';
import { useMfwThemeProvider } from 'myfitworld-utils';

const Snackbar = () => {
  const toastQueue: Array<Toast> = useStoreState(globalState, s => s.toastQueue);
  const theme = useMfwThemeProvider();

  const closeHandler = () => {
    globalState.update(state => {
      state.toastQueue = state.toastQueue.slice(1, state.toastQueue.length);
    })
  };

  if (toastQueue.length === 0) {
    return null;
  } else {
    const toast = toastQueue[0];

    return (
      <MuiSnackbar open={true} autoHideDuration={4000} onClose={closeHandler}>
        <Alert onClose={closeHandler} severity={toast.severity} elevation={6} variant="filled" style={{backgroundColor: toast.severity === 'success' ? theme.mfwMobTheme.primary : theme.mfwMobTheme.delete}}>
          {toast.message}
        </Alert>
      </MuiSnackbar>
    );
  }
};

export default Snackbar;
