const toggleShowPublic = (state = false, action: any) => {
  switch (action.type) {
    case 'SHOW_PUBLIC':
      return !state;
    default:
      return state;
  }
}

export default toggleShowPublic;
