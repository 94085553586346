import React, {useEffect, useState} from "react";
import {Activity, User} from "myfitworld-model";
import {Avatar, Box, Button, makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import usersApi from "../../api/usersApi";
import format from "date-fns/format";
import ReactionImage from "../ReactionImage";
import getLocalizedProperty from "../../utils/getLocalizedProperty";
import { WbSunnyOutlined, Brightness2Outlined } from "@material-ui/icons";
import { useMfwThemeProvider } from 'myfitworld-utils';
import { useIntl } from "react-intl";
import chatMessages from "./messages";


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
    width: "100%",
  },
  avatar: {
    height: 32,
    width: 32,
  },
  image: {
    cursor: "pointer",
    height: "auto",
    maxWidth: "100%",
    width: 380,
  },
  systemMessageRoot: {
    display: "flex",
    width: "100%",
  },
  bubbleContainer: {
    width: "100%",
  },
  systemMessageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderWidth: "1px",
    borderColor: theme.palette.primary.dark,
    borderStyle: "solid",
    borderRadius: 4,
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  flexColumn: {
    width: "100%",
  },
  dateText: {
    marginBottom: theme.spacing(1)
  },
  messageText: {
    color: "#CDCDCD",
  },
  viewDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  viewDetails: {
    color: "#CDCDCD",
    marginRight: 13,
  },
  systemMessageHeading: {
    fontSize: 18,
    color: "#CDCDCD",
  },
  iconVerticalFix: {
    paddingTop: 2,
  },
}));

const ActivityView = ({ activity, onViewDetails }: Props) => {
  const classes = useStyles();
  const {mfwMuiWebTheme} = useMfwThemeProvider();
  const {formatMessage, locale} = useIntl();
  
  const [client, setClient] = useState<User>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    usersApi([])
      .get(activity.threadId)
      .then((res) => setClient(res));
    return () => {
      setClient(undefined);
    }
   }, [activity.threadId]);

  const textLength = isMobile ? 50 : 60;

  return (
    <Box border={`2px solid ${mfwMuiWebTheme.palette.primary.light}`} padding='2%' marginTop='2%' borderRadius={5} style={{backgroundColor: !activity.read ? 'rbga(0,0,0,0.1)': ''}}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex' flexDirection='row'>
          <Avatar className={classes.avatar} src={client?.avatarURL}/>
          <Typography color="inherit" variant="h5" style={{marginLeft: 10, marginTop: 5, fontWeight: 'bold'}}>
            {client?.firstName + " " +client?.lastName}
          </Typography>
        </Box>
        {activity.message.dailyActivityType && 
          <Box display='flex' flexDirection='row'>
            {activity.message.dailyActivityType === 'morning' ? <WbSunnyOutlined/> : <Brightness2Outlined/>}
            <Typography variant="h5" style={{marginLeft: 5}}>{formatMessage(chatMessages.daily_activity_title).toUpperCase()}</Typography>
          </Box>
        }
        {activity.workoutId && 
          <Box display='flex' flexDirection='row'>
            { activity.reaction && <ReactionImage reaction={activity.reaction} />}
            <Typography variant="h5" style={{marginLeft: 5}}>{formatMessage(chatMessages.workout_completed).toUpperCase()}</Typography>
          </Box>
        }

      </Box>
      <Box>
          <Box mt={1} className={classes.bubbleContainer}>

                <Box className={classes.flexColumn}>
                  {activity?.message?.workout?.title &&
                    <Typography className={classes.dateText}>{getLocalizedProperty(locale, activity?.message?.workout?.title).length > textLength ? `${getLocalizedProperty(locale, activity?.message?.workout?.title).slice(0, textLength)}...` : getLocalizedProperty(locale, activity?.message?.workout?.title)}</Typography>
                  }

                  <Typography className={classes.dateText}>{format(activity.createdAt, "Y-MM-dd HH:mm")}</Typography>
                  <Typography className={classes.messageText}>{activity.text}</Typography>

                  <Box className={classes.viewDetailsContainer}>
                    <Button variant="text" color="primary" onClick={onViewDetails} endIcon={<ArrowForwardIosIcon />}>
                      {formatMessage(chatMessages.notification_details_label)}
                    </Button>
                  </Box>
                </Box>
              </Box>
        </Box>    
    </Box>
  );
};

export default ActivityView;

interface Props {
  activity: Activity;
  onViewDetails: (event?: any) => void;
}
