import { useCallback, useEffect, useState } from "react";
import { useQuery } from "../base";
import globalState, { GlobalState } from "../../globalState";
import { fetchOrganizationMembers } from "../../api/firebaseQueries/Organizations/trainers";
import { queryByLastSignIn, queryByName, sortUsersByName } from "../helpers";
import { OrganizationUser } from "myfitworld-model";
import useUpdateOrganizationClient, { OrgInUser } from "./useUpdateOrganizationClient";
import useUpdateCopyOkUser from "../Users/useUpdateCopyOkUser";
import { useIntl } from "react-intl";
import organizationsMessages from "./messages";

function useOrganizationMembers({ 
  organizationId, 
  isArchived, 
  isAdmin,
  nameSearchQuery,
  dateTimeQuery,
 }: { 
  organizationId?: string; 
  isArchived: boolean; 
  isAdmin:boolean;
  nameSearchQuery: string;
  dateTimeQuery: string; 
}) {
  const {formatMessage} = useIntl();
  const [filteredMembers, setFilteredMembers] = useState<OrganizationUser[]>([]);
  const {updateOrganizationClientQuery: updateOrganizationMemberFromHook} = useUpdateOrganizationClient();
  const {updateOrganizationCopyOkUserQuery: updateOrganizationCopyOkUserFromHook} = useUpdateCopyOkUser();

  const fetchOrganizationMembersQuery = useCallback(async () => {
    try {
      if (!organizationId) return [];
      return fetchOrganizationMembers({ organizationId, isArchived, isAdmin });
    } catch (error) {
      globalState.update((state: GlobalState) => {
        state.toastQueue.push({
          message: formatMessage(organizationsMessages.error_loading_Organization_Memebers),
          severity: "error",
        });
      });
    }
  }, [organizationId, isAdmin, isArchived, updateOrganizationMember, updateOrganizationCopyOkUser]);


  const { data, status, error, isLoading } = useQuery(fetchOrganizationMembersQuery);
  
  useEffect(() => {
    if (data) {
      let filteredMembersList = [...data];
      if (nameSearchQuery) {
        filteredMembersList = queryByName(filteredMembersList, nameSearchQuery);
      }

      if (dateTimeQuery) {
        filteredMembersList = queryByLastSignIn(filteredMembersList, dateTimeQuery, data);
      }
    
      filteredMembersList = sortUsersByName(filteredMembersList)

      setFilteredMembers(filteredMembersList);
    }
  }, [data, nameSearchQuery, dateTimeQuery]);


  function updateOrganizationMember({ userId, organizations }: { userId: string, organizations: OrgInUser[] }) {
    updateOrganizationMemberFromHook({ userId, organizations });  
    const updatedMembers = filteredMembers.map(member => {
      if (userId === member.userId) {
        const organization = organizations.find(org => org.id === member.orgId);
        if (organization) {
          member.role = organization.role;
          // Check if member needs to be removed
          if (member.archived !== organization.archived) {
            member.archived = organization.archived;
            return null;
          }
        }
      }
      return member;
    }).filter(member => member !== null) as OrganizationUser[]; // Filter out null values
  
    setFilteredMembers(updatedMembers);
  }
  
  function updateOrganizationCopyOkUser({ userId, copyOk }: { userId: string, copyOk: boolean }) {
    updateOrganizationCopyOkUserFromHook({ userId, copyOk });  
    const memberIndex = filteredMembers.findIndex(member => member.userId === userId);
    if (memberIndex !== -1) {
      const updatedMembers = [...filteredMembers]; 
      updatedMembers[memberIndex] = { ...updatedMembers[memberIndex], copyOk }; 
      setFilteredMembers(updatedMembers); 
    }
  }

  return {
    organizationMembers: filteredMembers,
    updateOrganizationMember,
    updateOrganizationCopyOkUser,
    statusFetchingMembers: status,
    errorFetchingMembers: error,
    isLoadingMembers: isLoading,
  };
}

export default useOrganizationMembers;
