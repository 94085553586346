import globalState, { GlobalState } from "../../globalState";
import { useIntl } from "react-intl";
import hookMessages from '../messages';
import { acceptWLClientRequest, rejectWLClientRequest } from "../../api/Client/client-requests";

const useWLClientsRequests = () => {

    const {formatMessage} = useIntl();

  const acceptRequest = async(userId: string, organizationId: string, userEmail: string) => {
    try{
        const res = await acceptWLClientRequest({userId, organizationId, userEmail});
        if(res){
          globalState.update((state: GlobalState) => {
            state.toastQueue.push({
            message: formatMessage(hookMessages.success_accept_wl_client),
            severity: "success",
            });
        });
        }
        return res.status;
    } catch (error) {
        globalState.update((state: GlobalState) => {
          state.toastQueue.push({
            message: formatMessage(hookMessages.error_adding_document),
            severity: "error",
          });
        }); 
      }
    }
 
    const rejectRequest = async (userId: string, organizationId: string, userEmail: string) => {
        try{
          const res = await rejectWLClientRequest({userId, organizationId, userEmail});
          if(res){
            globalState.update((state: GlobalState) => {
              state.toastQueue.push({
                message: formatMessage(hookMessages.success_reject_wl_client),
                severity: "success",
              });
            });
          }
          return res.status;  
        } catch (error) {
            globalState.update((state: GlobalState) => {
              state.toastQueue.push({
                message: formatMessage(hookMessages.error_adding_document),
                severity: "error",
              });
            }); 
          }
        }

  return {
    acceptRequest,
    rejectRequest
  };
}
export default useWLClientsRequests;
