import firebase from "firebase";
const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const setNotificationsToBeActive = (messagingTopic:string, messagingToken:string) : Promise<any> =>{
    if(messagingTopic === ""){return new Promise<null>(()=>null);}
    const rSendObj = {
        topic : messagingTopic,
        token : messagingToken
    };
    //messagingTopic represents current User ID
    // messagingToken represents current browser client
    return new Promise<any>(async resolve => {
        const token = await firebase.auth().currentUser?.getIdToken();
        await fetch(`${FUNCTIONS_BASE_URL}/webTrainerNotifications/subscribe`, {
            method: 'POST',
            body: JSON.stringify(rSendObj),
            headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? 'Bearer ' + token : ''
            }
        });
        return true;
    })

}

export const setNotificationsToBeInactive = (messagingTopic:string, messagingToken:string) : Promise<null> =>{
    if(messagingTopic === ""){return new Promise<null>(()=>null);}
    const rSendObj = {
        topic : messagingTopic,
        token : messagingToken
    };
    //messagingTopic represents current User ID
    // messagingToken represents current browser client
    return new Promise<any>(async resolve => {
        const token = await firebase.auth().currentUser?.getIdToken();
        await fetch(`${FUNCTIONS_BASE_URL}/webTrainerNotifications/unsubscribe`, {
            method: 'POST',
            body: JSON.stringify(rSendObj),
            headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? 'Bearer ' + token : ''
            }
        });
        return true;
    })
}