import React, {Fragment, useState} from 'react';
import {RouteComponentProps} from '@reach/router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../../components/TabPanel";
import EquipmentManagementPage from "./equipment/EquipmentManagementPage";
import GeneralEntityFactory from "./generalEntity/GeneralEntityPageFactory";
import {
  fitnessActivitiesApi,
  muscleGroupsApi,
  goalsApi,
  motorSkillsApi,
  sportsApi,
  healthIssuesApi,
  expertisesApi,
  durationConfigurationApi,
} from '../../api/common';
import {Box, Hidden, MenuItem, Select} from "@material-ui/core";
import { dropdownMobileTabStyle } from "../globalPagesStyles";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import configurationMessages from './messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownMobileTabStyle: dropdownMobileTabStyle(theme),
  }),
);

const ConfigurationManagementPage = (props: RouteComponentProps, IndexName: string) => {
  const [value, setValue] = useState(0);
  const {locale, formatMessage} = useIntl();
  const entities = [
    {
      id: 'equipment',
      label: "equipment",
      Component: EquipmentManagementPage
    },
    {
      id: 'fitness',
      label: 'activity',
      Component: GeneralEntityFactory(fitnessActivitiesApi, formatMessage(configurationMessages.activity), IndexName = "fitness", locale)
    },
    {
      id: 'muscle',
      label: 'muscle_groups',
      Component: GeneralEntityFactory(muscleGroupsApi, formatMessage(configurationMessages.muscle_group), IndexName = "muscle", locale)
    },
    {
      id: 'goals',
      label: 'goals',
      Component: GeneralEntityFactory(goalsApi, formatMessage(configurationMessages.goal), IndexName = "goals", locale)
    },
    {
      id: 'motor',
      label: 'motor_skills',
      Component: GeneralEntityFactory(motorSkillsApi, formatMessage(configurationMessages.motor_skill), IndexName = "motor", locale)
    },
    {
      id: 'sport',
      label: 'sport_list',
      Component: GeneralEntityFactory(sportsApi, formatMessage(configurationMessages.sport), IndexName = "sport", locale)
    },
    {
      id: 'health',
      label: 'health_issues',
      Component: GeneralEntityFactory(healthIssuesApi, formatMessage(configurationMessages.health_issue), IndexName = "health", locale)
    },
    {
      id: 'expertise',
      label: 'expertise',
      Component: GeneralEntityFactory(expertisesApi,formatMessage(configurationMessages.expertise), IndexName = "expertise", locale)
    },
    {
      id: 'durationConfiguration',
      label: 'duration',
      Component: GeneralEntityFactory(durationConfigurationApi, formatMessage(configurationMessages.duration), IndexName = "durationConfiguration", locale)
    }
  ]

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <Fragment>
      {/* for larger screens */}
      <Hidden smDown>
        <Box display="flex" justifyContent="center">
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='Content navigation'
              textColor='primary'
            >
              {entities.map((entity, i) => <Tab key={i} label={formatMessage((configurationMessages as any)[entity.label])}/>)}
            </Tabs>
          </Box>
        </Hidden>
        {/* For smaller screens */}
      <Hidden mdUp>
        <Box display="flex" justifyContent="center">
          <Select
              value={value}
              onChange={(e)=>{handleChange(e,e.target.value as number)}}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={classes.dropdownMobileTabStyle}
            >
              {
                entities.map((entity, tabIndex)=>
                  <MenuItem value={tabIndex}>
                    {formatMessage((configurationMessages as any)[entity.label])}
                  </MenuItem>
                )
              }
            </Select>
        </Box>
      </Hidden>
      {entities.map(({Component}, i) => (
        <TabPanel value={value} index={i} key={i}>
          <Component/>
        </TabPanel>
      ))}
    </Fragment>
  );
};

export default ConfigurationManagementPage;
