import {  defineMessages } from 'react-intl'

const messages = defineMessages({

  month: {
    id: 'corporate.month',
    defaultMessage: 'Month'
  },
  number_of_entries: {
    id: 'corporate.number_of_entries',
    defaultMessage: 'Number of entries'
  },
  corp_org_page_title: {
    id: 'corporate.corp_org_page_title',
    defaultMessage: 'CORP ORGANIZATION PAGE'
  },
  last_sign_in: {
    id: 'last_sign_in',
    defaultMessage: 'Last Sign In'
  },
  freeze: {
    id: 'corporate.freeze',
    defaultMessage: 'Freeze'
  },
  unfreeze: {
      id: 'corporate.unfreeze',
      defaultMessage: 'Unfreeze'
  },
  freeze_corporation: {
      id: 'corporate.freeze_corporation',
      defaultMessage: 'Freeze Corporation'
  },
  unfreeze_corporation: {
      id: 'corporate.freeze_corporation',
      defaultMessage: 'Unfreeze Corporation'
  },
  freeze_dialog: {
      id: 'corporate.freeze_dialog',
      defaultMessage: 'Are you sure you want to FREEZE this corporation?'
  },
  unfreeze_dialog: {
      id: 'corporate.unfreeze_dialog',
      defaultMessage: 'Are you sure you want to UNFREEZE this corporation?'
  },
  corporate_id: {
      id: 'corporate.corporate_id',
      defaultMessage: 'Corporate ID'
  },
  corporation: {
      id: 'corporate.corporation',
      defaultMessage: 'Corporation'
  },
  create_organization: {
      id: 'create_organization',
      defaultMessage: 'Create Organization'
  },
  freeze_client: {
      id: 'corporate.freeze_client',
      defaultMessage: 'Freeze Client'
  },
  unfreeze_client: {
      id: 'corporate.unfreeze_client',
      defaultMessage: 'Unfreeze Client'
  },
  no_values_provided: {
      id: 'corporate.no_values_provided',
      defaultMessage: 'No values provided'
  },
  corp_count: {
      id: 'corporate.corp_count',
      defaultMessage: 'Corp Count'
  },
  remove_client_from_corporation: {
    id: 'corporate.remove_client_from_corporation',
    defaultMessage: 'Remove Client from Corporation'
  },
  are_you_sure_you_want_to_remove_client_from_corporation: {
    id: 'corporate.are_you_sure_you_want_to_remove_client_from_corporation',
    defaultMessage: 'Are you sure you want to REMOVE client from corporation?'
  },
  corporate_user_ID: {
    id: 'corporate.corporate_user_ID',
    defaultMessage: 'Corporate User ID'
  },
  corp_count_by_month: {
    id: 'corporate.corp_count_by_month',
    defaultMessage: 'Corp Count by Month'
  },
  admins: {
    id: 'admins',
    defaultMessage: 'Admins'
  },
  trainers: {
    id: 'trainers',
    defaultMessage: 'Trainers'
  },
  archived_admins: {
    id: 'archived_admins',
    defaultMessage: 'Archived Admins'
  },
  archived_trainers: {
    id: 'archived_trainers',
    defaultMessage: 'Archived Trainers'
  },
  invitations: {
    id: 'invitations',
    defaultMessage: 'Invitations'
  },
  no_admins: {
    id: 'no_admins',
    defaultMessage: "You don't have any admins now."
  },
  no_archived_admins: {
    id: 'no_archived_admins',
    defaultMessage: "You don't have any archived admins now."
  },
  no_trainers: {
    id: 'no_trainers',
    defaultMessage: "You don't have any trainers now."
  },
  no_archived_trainers: {
    id: 'no_archived_trainers',
    defaultMessage: "You don't have any archived trainers now."
  },
})

export default messages;
