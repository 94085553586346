import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  error_updating_CopyOk_User: {
    id: 'error.updatingCopyOkUser',
    defaultMessage: 'Error updating copy ok in user!'
  },
  error_updating_archived_id:{
    id:'error.updatingArchivedId',
    defaultMessage: 'Error updating users archived chats!'
  },
  error_saving_data: {
    id: 'error.saving_data',
    defaultMessage: 'Error saving data!'
  }

})

export default messages;
