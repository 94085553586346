import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD052461myno9PCB-WFM9VJBePWkciCOjg",
    authDomain: "mfw-stage.firebaseapp.com",
    databaseURL: "https://mfw-stage.firebaseio.com",
    projectId: "mfw-stage",
    storageBucket: "mfw-stage.appspot.com",
    messagingSenderId: "429846860916",
    appId: "1:429846860916:web:1dcd5e8c5bd5575824ad21",
    measurementId: "G-X1GFP35QBW"
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyC5daxgGnmvH18gPdMbVPiefbcAPqKOR-I",
  authDomain: "mfw-prod.firebaseapp.com",
  databaseURL: "https://mfw-prod.firebaseio.com",
  projectId: "mfw-prod",
  storageBucket: "mfw-prod.appspot.com",
  messagingSenderId: "1061239110485",
  appId: "1:1061239110485:web:daee362b79ceeb07f48ddc",
  measurementId: "G-GLCR5WP2BQ"
};

export const app = firebase.initializeApp(process.env.REACT_APP_ENV === 'production' ? prodFirebaseConfig : firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com");
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
