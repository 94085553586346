import { useCallback, useEffect, useState } from "react";
import { Resource } from "myfitworld-model";
import ContentFilterValues from "../model/ContentFilter";
import useOrganization from "./useOrganization";
import { useMfwThemeProvider } from "myfitworld-utils";

function useEntityList<T extends Resource>(
  listProvider: (organizationId: string, wlOrgId: string, filter?: ContentFilterValues, sortKey?: string) => Promise<Array<T>>,
  filter?: ContentFilterValues,
  sortKey?: string,
) {
  const [data, setData] = useState<Array<T>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { organizationId } = useOrganization();
  const { theme } = useMfwThemeProvider();

  const loadData = useCallback(() => {
    if (organizationId) {
      let isMounted = true;
      setLoading(true);

      (async () => {
        try {
          const result = await listProvider(organizationId, theme.orgId, filter, sortKey);
          if (isMounted) {
            setData(result);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      })();

      return () => {
        isMounted = false;
      };
    }
  }, [organizationId, listProvider, theme.orgId, filter, sortKey]);

  useEffect(() => {
    const cleanup = loadData();
    return cleanup;
  }, [loadData]);

  return { data, loadData, loading };
}

export default useEntityList;
