import firebase from "firebase"
import { RegisterRequestType } from "myfitworld-model";

const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

interface RegisterFunctionInterfaceNewOrganizationData {
  firstName : string;
  lastName : string;
  organizationName? : string;
}
export const callRegisterFunction = async (email:string, password:string, confirmPassword:string, language: string = "en", data?: RegisterFunctionInterfaceNewOrganizationData) =>{
  const preBody : any = {
        email,
        password,
        confirmPassword,
        data
  }

  if(data && data.organizationName){
    preBody.requestType = RegisterRequestType.ORGANIZATION
  }
  if(data && !data.organizationName && data.firstName && data.lastName){
    preBody.requestType = RegisterRequestType.CLIENT_WITH_NO_ORGANIZATION
  }
  
    return fetch(`${FUNCTIONS_BASE_URL}/auth/register?language=${language}`, {
      method: 'POST',
      body : JSON.stringify(preBody),
      headers: { 'Content-Type': 'application/json' }
    })
}

export const callLoginFunction = async (email:string, password:string) =>{
    return fetch(`${FUNCTIONS_BASE_URL}/auth/login`, {
      method: 'POST',
      body : JSON.stringify({
        email,
        password
      }),
      headers: { 'Content-Type': 'application/json' }
    })
}

export const callResetPasswordFunction = async (email:string) =>{
    return fetch(`${FUNCTIONS_BASE_URL}/auth/reset-password?email=${encodeURIComponent(email)}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
}

export const callChangePasswordFunction = async (oldPwd: string, newPwd:string, confNewPwd:string) =>{
  const token = await firebase.auth().currentUser?.getIdToken();
  return fetch(`${FUNCTIONS_BASE_URL}/auth/change-password`, {
    method: 'POST',
    body: JSON.stringify({
      "oldPassword" : oldPwd,
      "newPassword" : newPwd,
      "confirmNewPassword" : confNewPwd
    }),
    headers: {
        'Authorization': token ? 'Bearer ' + token : '',
        'Content-Type': 'application/json' }
  })
}

export const callResetPasswordPost = async (password:string, confirmPassword:string, token:string) =>{
  return fetch(`${FUNCTIONS_BASE_URL}/auth/reset-password`, {
    method: 'POST',
    body : JSON.stringify({
      password,
      confirmPassword,
      token
    }),
    headers: { 'Content-Type': 'application/json' }
  })
}

export const callDeleteAccount = async(fromPath: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
    return fetch(`${FUNCTIONS_BASE_URL}/deleteFirestoreData/user-client/${firebase.auth().currentUser?.uid}`, {
    method: 'DELETE',
    body: JSON.stringify({
      "fromPath" : fromPath,
    }),
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  })
};

export const callResendVerificationEmail = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  return fetch(`${FUNCTIONS_BASE_URL}/auth/resend-verification-email`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json'
    }
  });
};