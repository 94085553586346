export const SortByOptions = [
    { 
        id: "asc",
        name: {
            es: "Ascendente", 
            de: "Aufsteigend",
            hr: "Uzlazno",
            en: "Ascending",
            sr: "Uzlazno",
            bg: "Възходящ", 
            ro: "Ascendent",
            ru: "Восходящий"
          }
    },
    {
        id: "desc",
        name: {
            es: "Descendente",
            de: "Absteigend", 
            hr: "Silazno",
            en: "Descending",
            sr: "Silazno",
            ro: "Descendent",
            ru: "Нисходящий"
          }          
    },
  ];
  