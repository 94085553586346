import ContentFilterValues from "../model/ContentFilter";
import globalState from "../globalState";
import {Role} from "myfitworld-model";
import getLocalizedProperty from "../utils/getLocalizedProperty";

const keys = [
  'healthIssues', 'suitableForHealthIssues', 'motorSkills', 'muscleGroups', 'secondaryMuscleGroups',
  'difficultyLevel', 'fitnessActivity', 'sports', 'equipment', 'goals', 'muscleRegime',
  'goodFor', 'mealTypes', 'counterProductiveFor', 'timePreparation', 'diet', 'micronutrient', 'category',
  'glycemicIndexRange', 'glycemicLoadRange','durationInWeeks' 
];

function hasSubArray(master:any, sub:any) : boolean | number {
  let rval = 0;
  master.forEach((elem:any)=>{
    if(sub.indexOf(elem) != -1){rval++}
  })
  return rval===0?false:rval;
}

const clientSideContentFilter = (list: any[], filter: ContentFilterValues, filterByAuthor: boolean, orgId?: string, endpoint?: string) => {
  const userRole = globalState.getRawState().currentRole;
  const userId = globalState.getRawState().currentUserId;
  const currentOrganization = globalState.getRawState().currentOrganization;

  let res = [ ...list ];
  const currentOrg = globalState.getRawState().currentOrganization;

  if (filter.isLockedContentDisplayed) {
    return list.filter(_ => _.isContentLocked === true && _.authorId === userId);
  }
  if(filter.showMyMfwNet){
    res = res.filter(_ => filter.archived !== undefined ? (_.archived ? _.archived === filter.archived : true) : _.archived !== true);
    return res.filter(_ => _.isPublic === true && _.authorId === userId )
  }
  
  if (filter.showPremium && currentOrg && currentOrg.isPremiumSwitchEnabled) {
    res = res.filter(_ => _.premiumContent === true);
    return res
  }

      //if all keys are empty
      // empty string or empty lists
      // then dont do filter below
      let isFilterEmpty = Object.keys(filter).every((e:string)=>{
        if(["archived", "showMFWOriginals", "showORGOriginals", "showPublic", "showPremium", "isLockedContentDisplayed"].includes(e)){return true}
        const key = e as (
          'sports' | 'muscleGroups' | 'secondaryMuscleGroups' | 'motorSkills' | 'muscleRegime' |
          'healthIssues' | 'suitableForHealthIssues' | 'fitnessActivity' | 'equipment' | 'difficultyLevel' | 'goals' | 'goodFor' | 'mealTypes' 
          | 'counterProductiveFor' | 'timePreparation' | 'diet'  | 'micronutrient' | 'category' | 'glycemicIndexRange' | 'glycemicLoadRange'| 'durationInWeeks'
        );
        if(filter[key].length === 0 || filter[key] === "" ){return true}
        //if it is empty string or empty array
        return false
      })
    
      res = res.filter(_ => filter.archived !== undefined ? (_.archived ? _.archived === filter.archived : true) : _.archived !== true);
      // let isSomeEmptyArray
      if(!isFilterEmpty && window.location.href.includes("form=open")){
        //filter by OR and sort at the top with the highest number of filters
            res = res.map(_ => {
              let filterMatchCounter = 0;
              for (let k of keys) {
                const key = k as (
                  'sports' | 'muscleGroups' | 'secondaryMuscleGroups' | 'motorSkills' | 'muscleRegime' |
                  'healthIssues' | 'suitableForHealthIssues' | 'fitnessActivity' | 'equipment' | 'difficultyLevel' | 'goals' | 'goodFor' | 'mealTypes' 
                  | 'counterProductiveFor' | 'timePreparation' | 'diet' | 'micronutrient' | 'category'| 'glycemicIndexRange' | 'glycemicLoadRange' | 'durationInWeeks'
                );
                
                if (key !== 'difficultyLevel' && key !== 'muscleRegime' && key != "sports" && key !=="durationInWeeks" && key!== "timePreparation" && key !== "category" && key!== 'glycemicIndexRange' && key!=='glycemicLoadRange') {

                  if(filter[key]?.length > 0){
                          if(Array.isArray(_[key]) && Array.isArray(filter[key])){
                            //@ts-ignore
                            let boolval =  _[key] && hasSubArray(_[key].sort(), filter[key].sort())
                            if(boolval){filterMatchCounter++}
                          }
                      }
                }else if (key === 'glycemicIndexRange' && filter.glycemicIndexRange) {
                  res = res.filter(_ => {            
                    if(filter.glycemicIndexRange.includes(_[key])){
                      return true;
                    } else return false;
                  });
                } else if (key === 'glycemicLoadRange' && filter.glycemicLoadRange) {
                  res = res.filter(_ => {            
                    if(filter.glycemicLoadRange.includes(_[key])){
                      return true;
                    } else return false;
                  });
                } else if (key === 'timePreparation' && filter.timePreparation) {
                  res = res.filter(_ => {            
                    if(filter.timePreparation.includes(_[key])){
                      return true;
                    } else return false;
                  });
                }  else if (key === 'category' && filter.category) {
                  res = res.filter(_ => {            
                    if(filter.category.includes(_[key])){
                      return true;
                    } else return false;
                  });
                } else if (key === 'durationInWeeks' && filter.durationInWeeks) {
                  res = res.filter(_ => {            
                    if(filter.durationInWeeks.includes(_[key])){
                      return true;
                    } else return false;
                  });
                } else
                if (key === 'difficultyLevel' && filter.difficultyLevel) {
                    if(Array.isArray(filter.difficultyLevel)){
                      //@ts-ignore
                      let boolval = filter.difficultyLevel.indexOf(String(_.difficultyLevel)) != -1;
                      if(boolval){filterMatchCounter++}
                    }

                } else if (key === 'muscleRegime' && filter.muscleRegime) {
                  let boolval = _.muscleRegime === filter.muscleRegime
                  if(boolval){filterMatchCounter++}
                }

                  else if (key === 'sports' && filter.sports.length > 0) {
                        if(Array.isArray(_.sports) && Array.isArray(filter.sports)){
                        let boolval = hasSubArray(_.sports.sort(), (Array.isArray(filter.sports)?filter.sports: [] ).sort())
                        if(boolval){filterMatchCounter++}
                        }
                  }
              }
              _.filterMatchCounter = filterMatchCounter;
                return _
            });
            //filter out no matching cases
            res = res.filter(e=>e.filterMatchCounter > 0)
            res = res.sort((a, b) => (getLocalizedProperty(a.title) < getLocalizedProperty(b.filterMatchCounter)) ? 1 : -1)
            res = res.sort((a, b) => (a.filterMatchCounter < b.filterMatchCounter) ? 1 : -1)
      } // form=opem if end
      else if (!isFilterEmpty){
      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      //normal filter AND
      for (let k of keys) {
        const key = k as (
          'sports' | 'muscleGroups' | 'secondaryMuscleGroups' | 'motorSkills' | 'muscleRegime' |
          'healthIssues' | 'suitableForHealthIssues' | 'fitnessActivity' | 'equipment' | 'difficultyLevel' | 'goals'| 'glycemicIndexRange' | 'glycemicLoadRange'| 'durationInWeeks' | "timePreparation" | "category" | 'diet'
        );
        if (key !== 'difficultyLevel' && key !== 'muscleRegime' && key != "sports" && key!=="durationInWeeks" && key !=="timePreparation" && key!=="category" && key !=="glycemicIndexRange" && key !=='glycemicLoadRange') {
          if(filter[key]?.length > 0){
            res = res.filter(_ => {
                  if(!Array.isArray(_[key])){return false}
                  if(!Array.isArray(filter[key])){return false}
                  if(filter[key].length === 0 ){return true}
                  //@ts-ignore
                  return _[key] && hasSubArray(_[key].sort(), filter[key].sort())
                })
              }
        } else if (key === 'glycemicIndexRange' && filter.glycemicIndexRange) {
          res = res.filter(_ => {            
            if(filter.glycemicIndexRange.includes(_[key])){
              return true;
            } else return false;
          });
        } else if (key === 'glycemicLoadRange' && filter.glycemicLoadRange) {
          res = res.filter(_ => {            
            if(filter.glycemicLoadRange.includes(_[key])){
              return true;
            } else return false;
          });
        } else if (key === 'timePreparation' && filter.timePreparation) {
          res = res.filter(_ => {            
            if(filter.timePreparation.includes(_[key])){
              return true;
            } else return false;
          });
        } else if (key === 'category' && filter.category) {
          res = res.filter(_ => {            
            if(filter.category.includes(_[key])){
              return true;
            } else return false;
          });
        } else if (key === 'durationInWeeks' && filter.durationInWeeks) {
          res = res.filter(_ => {            
            if(filter.durationInWeeks.includes(_[key])){
              return true;
            } else return false;
          });
        } else if (key === 'difficultyLevel' && filter.difficultyLevel) {
          res = res.filter(_ => {
            if(!Array.isArray(filter.difficultyLevel)){return false}
            if(filter.difficultyLevel?.length === 0 ){return true}
            //@ts-ignore
            return filter.difficultyLevel.indexOf(String(_.difficultyLevel)) != -1;
            //@ts-ignore
          });
          
        } else if (key === 'muscleRegime' && filter.muscleRegime) {
          res = res.filter(_ =>  _.muscleRegime === filter.muscleRegime[0])
        }

          else if (key === 'sports' && filter.sports?.length > 0) {
              res = res.filter(_ => {
                if(!Array.isArray(_.sports)){return false}
                if(!Array.isArray(filter.sports)){return false}
                return _.sports && hasSubArray(_.sports.sort(), filter.sports.sort())
              })
          }
      }
    }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  //mfw originals
  if ((userRole === Role.Trainer || Role.AssistantTrainer || Role.Admin || Role.SuperAdmin) && 
    !filter.showORGOriginals && !filter.showPublic && filter.showMFWOriginals && !filter.isLockedContentDisplayed) {

    if (userRole === Role.SuperAdmin) {
      res = res.filter(_ => _.organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID);
    } else {
      res = res.filter(_ => {
        if (endpoint === 'exercises') {
          return _.organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID || (_.authorId === userId && !_.orgContent)
        } else {
          return (_.organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID && !_.premiumContent) || (_.authorId === userId && !_.orgContent)
        }
      });
    }  
  }

  // org content filter
  if (!filter.showMFWOriginals && filter.showORGOriginals && !filter.showPublic && !filter.showPremium) {
    res = res.filter(_ => (_.organizationId === currentOrganization?.id && _.orgContent) || _.authorId === userId);
  }

  // no filters
  if (!filter.showMFWOriginals && !filter.showORGOriginals && !filter.showPublic && !filter.showPremium) {
    if (userRole === Role.SuperAdmin) {
      res = res.filter(_ => !_.premiumContent && _.organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID);
    } else {
      res = res.filter(
        _ => _.organizationId !== process.env.REACT_APP_MFW_ORGANIZATION_ID && _.authorId === userId && !_.orgContent
      );
    }
  }

  // public filter
  if ((userRole === Role.Trainer || Role.AssistantTrainer || Role.Admin) && !filter.showORGOriginals && !filter.showMFWOriginals) {
    if (filter.showPublic) {
      res = orgId === process.env.REACT_APP_MFW_ORGANIZATION_ID ? res.filter(_ => _.isPublic === true ) : res.filter(_ => _.isPublic === true && _.organizationId === orgId);
    }
  }

    // premium
  if (filter.showPremium) {
      res = res.filter(_ => _.premiumContent === true);
  }


  return res;
};

export default clientSideContentFilter;
