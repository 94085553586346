import React from 'react';
import { availableLocales } from './languages';

interface Ctx {
  language: string,
  languages: string[],
  setLanguage: (_: string) => void,
  registerField: (_: string) => void,
  deregisterField: (_: string) => void,
  registeredFields: Array<string>,
}

const defaultValue = {
  language: 'en',
  languages: availableLocales,
  setLanguage: (_: string) => { },
  registerField: (_: string) => { },
  deregisterField: (_: string) => { },
  registeredFields: [],
}

export default React.createContext<Ctx>(defaultValue)
