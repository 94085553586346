import { Enumerable } from 'myfitworld-model';
import getLocalizedProperty from "./getLocalizedProperty";

const buildKeyToNameMap = (locale: string, list: Array<Enumerable>) => {
  return list.reduce((acc: { [key: string]: string }, item) => {
    acc[item.id || ''] = getLocalizedProperty(locale, item.name);
    return acc;
  }, {});
};
export default buildKeyToNameMap;
