import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Organization} from "myfitworld-model";
import {firestore} from "../firebase";

const OrganizationsProviderContext = createContext<{
  organizations: Array<Organization> | null,
}>({organizations: null});

export const OrganizationsProvider = ({children}: PropsWithChildren<any>) => {
  const [organizations, setOrganizations] = useState<Array<Organization> | null>(null);

  useEffect(() => {
    firestore.collection("/organizations").onSnapshot((snapshot) => {
      setOrganizations(snapshot.docs.map(item => ({id: item.id, ...item.data()}) as Organization));
    });
  }, []);

  return <OrganizationsProviderContext.Provider value={{organizations: organizations}}>
    {children}
  </OrganizationsProviderContext.Provider>
}


export const useOrganizationsProvider = () => {
  const context = useContext(OrganizationsProviderContext);

  if (context === undefined) {
    throw new Error(
      'useExerciseProvider must be used within an ExerciseProvider'
    );
  }
  return context;
};
