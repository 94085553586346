import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(1),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      // [theme.breakpoints.up('md')]: {
      //   backgroundColor: theme.palette.primary.main,
      // },
      // [theme.breakpoints.up('lg')]: {
      //   backgroundColor: green[500],
      // },
    },
  }),
);

const PageContainer = ({ children } : { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      { children }
    </div>
  );
};

export default PageContainer;
