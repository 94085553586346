import { useState, useEffect, useCallback } from "react";

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAddToHomeScreenPrompt(): [(IBeforeInstallPromptEvent | null), (() => (Promise<void>)), (() => boolean), string] {

  const [prompt, setState] = useState<IBeforeInstallPromptEvent | null>(null);
  const [browserType, setBrowserType] = useState<string>('');

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(new Error('Tried installing before browser sent "beforeInstallPrompt" event'));
  };

  const isIos = useCallback(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }, [])

  const checkBrowser = useCallback(() => {
    if (isIos()) {
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
    
  // Discard Safari since it also matches Chrome
      if ((chromeAgent) && (safariAgent)) {
       safariAgent = false;
      }
      setBrowserType(safariAgent? "Safari" : "none");
    }
  }, [isIos, setBrowserType])

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setState(e);
    };

    checkBrowser();

    window.addEventListener("beforeinstallprompt", ready as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
    };
  }, [checkBrowser, prompt]);

  return [prompt, promptToInstall, isIos, browserType];
}
