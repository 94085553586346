import React from 'react';
import { GeneralEntity } from "myfitworld-model";
import EntityManager from "../../../entity/EntityManager";
import GeneralEntityListItem from "./GeneralEntityListItem";
import GeneralEntityForm from "./GeneralEntityForm";
import { ApiInterface } from "../../../api/dataInterfaceFactory";
import PageContainer from "../../PageContainer";
import { IntlShape } from 'react-intl'; // Import IntlShape type

const GeneralEntityFactory = (
  api: ApiInterface<GeneralEntity>,
  entityName: string,
  IndexName: string,
  locale: string // Pass intl as an argument
) => () => (
  <PageContainer>
    <div style={{ flexGrow: 1 }}>
      <EntityManager<GeneralEntity>
        entityName={entityName}
        apiInterface={api}
        ListItemComponent={GeneralEntityListItem}
        FormComponent={GeneralEntityForm}
        emptyStateTitle={`No registered items, start by adding a ${entityName}.`}
        sortKey={`name.${locale}`}
        listItemHeight={62}
        IndexName={IndexName}
      />
    </div>
  </PageContainer>
);

export default GeneralEntityFactory;
