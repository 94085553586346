import { Box, Button } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import chatMessages from "./messages";

const LoadMoreButton = ({onPress}: {onPress: () => void}) => {
    const {formatMessage} = useIntl();
    return(
        <Box justifyContent='center' alignSelf='center' marginTop={2} marginBottom={2} display='flex'>
            <Button onClick={onPress} style={{backgroundColor: 'gray', color: 'white', borderRadius: 15}}>{formatMessage(chatMessages.load_more)}</Button>
        </Box>
    );
};
export default LoadMoreButton;