import React from 'react';
import EquipmentListItem from './EquipmentListItem';
import EquipmentForm from './EquipmentForm';
import EntityManager from '../../../entity/EntityManager';
import PageContainer from "../../PageContainer";
import {equipmentApi} from "../../../api/common";
import {Equipment} from "myfitworld-model";
import {makeStyles} from "@material-ui/core/styles";
import { useIntl } from 'react-intl';
import equipmentMessages from './messages';

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const EquipmentManagementPage = () => {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const { locale } = useIntl()
  return (
    <PageContainer>
      <div className={classes.root}>
        <EntityManager<Equipment>
          entityName={'Equipment'}
          entityCreateHelper={formatMessage(equipmentMessages.describe_exercise_equipment_preferences)}
          apiInterface={equipmentApi}
          ListItemComponent={EquipmentListItem}
          FormComponent={EquipmentForm}
          emptyStateTitle={formatMessage(equipmentMessages.add_the_first_equipment_to_get_started)}
          sortKey={`name.${locale}`}
          listItemHeight={62}
          IndexName = {"equipment"}
        />
      </div>
    </PageContainer>
  )
};

export default EquipmentManagementPage;
