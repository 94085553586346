import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";

export const useDrawerStyles = (theme: Theme, isMobile: boolean) =>
  makeStyles(() =>
    createStyles({
      drawerPaper: {
        width: 500,
        padding: theme.spacing(4, 4),
        borderLeft:`1px solid ${theme.palette.primary.main}`,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          padding: theme.spacing(4, 2),
        },
      },
      drawerHeader: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width:'100%',
        top: 0,
        padding:'10px 0px',
        backgroundColor:theme.palette.background.default,
        zIndex:150,
      },
    })
  );
