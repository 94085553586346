import React from "react";

interface StringStyleParserProps {
  text: string;
  customStyle?: React.CSSProperties;
}

export const StringStyleParser: React.FC<StringStyleParserProps> = ({ text, customStyle }) => {
  const parseString = (text: string): (string | JSX.Element)[] => {
    const regex = /\$\$(.*?)\$\$/g;
    const parts: (string | JSX.Element)[] = [];
    let lastIndex = 0;
    let match;

    // Loop through all the matches
    while ((match = regex.exec(text)) !== null) {
      const [fullMatch, boldText] = match;

      // Add the text before the match
      if (lastIndex !== match.index) {
        parts.push(text.slice(lastIndex, match.index));
      }

      // Add the styled text based on customStyle prop
      parts.push(
        <span key={match.index} style={customStyle}>
        {boldText}
        </span>
    );

      // Update the lastIndex to continue after the matched text
      lastIndex = regex.lastIndex;
    }

    // Add any remaining text after the last match
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }

    return parts;
  };

  return <p>{parseString(text)}</p>;
};
