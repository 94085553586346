import { Store } from 'pullstate';
import GeneralEntity from 'myfitworld-model/dist/firestoreCollectionTypes/GeneralEntity';
import {Organization, Role} from "myfitworld-model";

export interface Toast {
  message: string;
  severity: "success" | "error"
}

export interface Cache {
  data: Array<GeneralEntity>;
  loadedAt: Date;
}

export interface GlobalState {
  currentRole?: Role;
  currentUserId?: string;
  currentOrganization?: Organization;
  globalLoadingQueue: Array<number>;
  toastQueue: Array<Toast>;
  equipmentCache: Cache;
  muscleGroupsCache: Cache;
  sportsCache: Cache;
  motorSkillsCache: Cache;
  healthIssuesCache: Cache;
  fitnessActivitiesCache: Cache;
  goalsCache: Cache;
  expertisesCache: Cache;
  language?:string;
  dietCache?: Cache;
  mealTypesCache?: Cache;
  goodForCache?: Cache;
  counterProductiveForCache?: Cache;
  timePreparationCache?: Cache;
  //totalKcalCache?: Cache;
  categoryCache?: Cache;
  micronutrientCache?: Cache;
  glycemicIndexRangeCache?: Cache;
  glycemicLoadRangeCache?: Cache;
  durationInWeeksCache?: Cache;
  durationConfigurationCache: Cache;
}

const globalState = new Store({
  currentRole: undefined,
  currentUserId: undefined,
  globalLoadingQueue: [],
  toastQueue: [],
  equipmentCache: { data: [], loadedAt: new Date() },
  muscleGroupsCache: { data: [], loadedAt: new Date() },
  sportsCache: { data: [], loadedAt: new Date() },
  motorSkillsCache: { data: [], loadedAt: new Date() },
  healthIssuesCache: { data: [], loadedAt: new Date() },
  fitnessActivitiesCache: { data: [], loadedAt: new Date() },
  goalsCache: { data: [], loadedAt: new Date() },
  expertisesCache: { data: [], loadedAt: new Date() },
  dietCache: { data: [], loadedAt: new Date() },
  mealTypesCache: { data: [], loadedAt: new Date() },
  goodForCache: { data: [], loadedAt: new Date() },
  counterProductiveForCache: { data: [], loadedAt: new Date() },
  timePreparationCache: { data: [], loadedAt: new Date() },
  //totalKcalCache: { data: [], loadedAt: new Date()},
  categoryCache: { data: [], loadedAt: new Date()},
  micronutrientCache: { data: [], loadedAt: new Date()},
  glycemicLoadRangeCache: { data: [], loadedAt: new Date()},
  glycemicIndexRangeCache: { data: [], loadedAt: new Date()},
  durationInWeeksCache : {data:[], loadedAt: new Date() },
  durationConfigurationCache: { data: [], loadedAt: new Date() },
  language:""
} as GlobalState);

export default globalState;
