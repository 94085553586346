import React, {useMemo, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid/Grid';
import { KeyboardArrowDown } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { useIntl } from 'react-intl';
import getLocalizedProperty from '../utils/getLocalizedProperty';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const MultiSelectField = ({
  label,
  options,
  value,
  onChange,
  required = false,
  disabled,
  isMultiple,
  errors
} : {
  label: string,
  options: Array<any>,
  value?: Array<string> | string,
  onChange: (event: any) => void,
  required?: boolean,
  disabled?: boolean,
  isMultiple?: boolean,
  errors?: any
}) => {
    const {locale} = useIntl();
    const optionsMap = useMemo(() => options.reduce((acc: { [key: string]: string }, option) => {
    acc[option.id || ''] = option.name.en ? getLocalizedProperty(locale, option.name): option.name;
    return acc;
  }, {}), [options]);
  const {theme} = useMfwThemeProvider();
  const [isOpen, setOpen]= useState<boolean>(false);

  return (
    <FormControl
      variant="filled"
      required={required}
      fullWidth
      margin="dense"
      error={errors && !!errors.category}
    >
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        multiple={isMultiple}
        renderValue={(selected) => (
          <Grid container spacing={1}>
            {Array.isArray(selected) && (selected as string[])
              .filter(option => optionsMap[option] !== undefined)
              .map((value) => (
                <Grid item key={value}>
                  <Chip label={optionsMap[value]} />
                </Grid>
              ))
            }
            {typeof(selected) === 'string' && (selected as string) && 
              optionsMap[selected] !== undefined &&
              (
                <Grid item key={selected}>
                  <Chip label={optionsMap[selected]} />
                </Grid>
              )
            }     
          </Grid>
        )}
        value={value || []}
        MenuProps={MenuProps}
        onOpen={()=>{setOpen(true)}} 
        onClose={()=>{setOpen(false)}}
        onChange={e => onChange((e as any).target.value) }
        disabled={disabled}
        IconComponent={() =>isOpen ?  <CloseIcon style={{color: theme.theme.text.primary, height:'20px'}}/>: <KeyboardArrowDown style={{color: theme.theme.text.primary, height:'20px'}}/>}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name.en ? getLocalizedProperty(locale, option.name): option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectField;
