import { BaseTextFieldProps } from "@material-ui/core";

export interface LocalizedTextFieldProps extends BaseTextFieldProps {
    formatText?:{
      bulletPoint?: boolean,
      tabForNumbers?: boolean,
    }
}

export interface setCursorProps {
  input: EventTarget & HTMLInputElement,
  newValue: string,
  oldValue: string,
  oldSelectionStart: number,
  oldSelectionEnd: number
}
  
export function setCursor({props}:{props:setCursorProps}) {
  // Calculate new cursor position based on old position and length change
  const diff = props.newValue.length - props.oldValue.length;
  const newSelectionStart = Math.max(0, props.oldSelectionStart + diff);
  const newSelectionEnd = Math.max(0, props.oldSelectionEnd + diff);

  // Restore cursor position
  props.input.setSelectionRange(newSelectionStart, newSelectionEnd);
}

export function formatBulletPoints(event: React.ChangeEvent<HTMLInputElement>) {
  const input = event.target;
  const oldValue = input.value;
  const oldSelectionStart = input.selectionStart as number;
  const oldSelectionEnd = input.selectionEnd as number;

  // Replace '- ' with bullet point
  const newValue = oldValue.split("\n").map(line => {
    if (line.trim().startsWith("- ")) {
      return `\t• ${line.substring(2)}`;
    }
    return line;
  }).join("\n");

  input.value = newValue;

  setCursor({props: {input: input, newValue:newValue,oldValue:oldValue, oldSelectionStart: oldSelectionStart, oldSelectionEnd: oldSelectionEnd}})
}
export function formatTabForNumbers(event: React.ChangeEvent<HTMLInputElement>) {
  const input = event.target;
  const oldValue = input.value;
  const oldSelectionStart = input.selectionStart as number;
  const oldSelectionEnd = input.selectionEnd as number;

  // Replace '- ' with bullet point
  const newValue = oldValue.split("\n").map(line => {
    // Check if the line starts with a tab followed by a number and period sequence
    if (!line.startsWith("\t") && line.trim().match(/^\d+\.\s/)) {
      return `\t${line}`;
    }
    return line;
  }).join("\n"); 
  input.value = newValue;
  setCursor({props: {input: input, newValue:newValue,oldValue:oldValue, oldSelectionStart: oldSelectionStart, oldSelectionEnd: oldSelectionEnd}})
}
