import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {BillingDetails, Organization} from "myfitworld-model";
import {useUserProvider} from "./UserProvider";
import * as firebase from 'firebase';
import {firestore} from "../firebase";
import organizationsApi from "../api/organizationsApi";
import isString from 'lodash/isString';
import {getOrganizationBillingDetails} from "../api/billingDetailsApi";
import { useMfwThemeProvider } from 'myfitworld-utils';

interface OrganizationExtended extends Organization {
  quantity: number
}

type OrganizationContextState = {
  availableOrganizations?: Organization[],
  selectedOrganization?: OrganizationExtended,
}

const OrganizationContext = createContext<[OrganizationContextState, (organization: Organization) => void]>([{}, (_) => {
}])

const updateCurrentOrganization = (userId: string | undefined, organization: Organization) => {
  if (!userId) return;
  firestore.collection('users').doc(userId).set({
    currentOrganization: organization.id
  }, {merge: true})
    .catch(err => console.error('Failed to update users currentOrganization', err))
};

export const OrganizationProvider = ({children}: PropsWithChildren<any>) => {
  const [organizationContextState, setOrganizationContextState] = useState<OrganizationContextState>({});
  const {user, organizationOverride} = useUserProvider();
  const {updateTheme} = useMfwThemeProvider();

  const selectOrganization = (organization: Organization) => {
    updateCurrentOrganization(user?.id, organization);
    organization?.id && getOrganizationBillingDetails(organization?.id).then(
      (res) => {
        setOrganizationContextState({
          ...organizationContextState, selectedOrganization: {
            ...organization,
            quantity: (res as unknown as BillingDetails)?.stripe?.quantity || 0
          }
        });
      }
    );
  }

  useEffect(() => {
    if (!user || !user.id || !user.organizations) return;

    return firestore
      .collection('organizations')
      .where(firebase.firestore.FieldPath.documentId(), 'in', user.organizations.map(item => {
        if (isString(item)) {
          return item
        } else {
          //@ts-ignore
          return item.id
        }
      }))
      .onSnapshot(snapshot => {
        if (!user.isSuperAdmin) {
          const availableOrganizations = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()} as Organization))
          const currentOrganization = user.currentOrganization
            ? availableOrganizations.find(org => org.id === user.currentOrganization)
            : availableOrganizations[0];

          if (!!currentOrganization && user.currentOrganization !== currentOrganization.id) {
            updateCurrentOrganization(user.id, currentOrganization!);
          }
          if(currentOrganization?.haveRebrand && currentOrganization?.rebrandOptions?.colors) {
            const rebrandColors = {
              primaryColor: currentOrganization?.rebrandOptions?.colors.primaryColor,
              secondaryColor: currentOrganization?.rebrandOptions?.colors.secondaryColor,
              textColor: currentOrganization?.rebrandOptions?.colors.textColor,
              backgroundColor: currentOrganization?.rebrandOptions?.colors.backgroundColor,
              logo: currentOrganization?.rebrandOptions?.logoURL
            }
            updateTheme(rebrandColors);
          }
          currentOrganization?.id && getOrganizationBillingDetails(currentOrganization?.id).then(
            (res) => {
              setOrganizationContextState({
                availableOrganizations: availableOrganizations,
                selectedOrganization: {
                  ...currentOrganization,
                  quantity: (res as unknown as BillingDetails)?.stripe?.quantity || 0
                } as OrganizationExtended
              });
            }
          );
        }
      })
  }, [user, setOrganizationContextState])

  useEffect(() => {
    if (organizationOverride) {
      organizationsApi.get(organizationOverride)
        .then(resultOrganization => {

          resultOrganization?.id && getOrganizationBillingDetails(resultOrganization?.id).then(
            (res) => {
              setOrganizationContextState({
                selectedOrganization: {
                  ...resultOrganization,
                  quantity: (res as unknown as BillingDetails)?.stripe?.quantity || 0
                }
              });
            }
          );
        })
        .catch(err => console.error('Org not found ', organizationOverride, err))
    }
  }, [organizationOverride])

  return <OrganizationContext.Provider
    value={[organizationContextState, selectOrganization]}>{children}</OrganizationContext.Provider>
}

export const useOrganizationProvider = () => {
  const context = useContext(OrganizationContext);

  if (context === undefined) {
    throw new Error(
      'useOrganizationProvider must be used within an OrganizationProvider'
    );
  }
  return context;
};
