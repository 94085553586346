import { UserMessagePreferences, deletedChatInfo } from "myfitworld-model/src/firestoreCollectionTypes/User";
import { ChatPreferenceOptionsProps } from "../chat/PreferenceChatDialog";
import { User } from "myfitworld-model";


function handleArchiveChat(updateUserMessagePreferences:any, user:User | undefined, id?:string) {
    if(id && user?.id){
        let newMessagePreference:UserMessagePreferences = user?.messagePreferences || {archivedIds:[]};
        newMessagePreference.archivedIds?.push(id);
        updateUserMessagePreferences({ userId: user.id, messagePreference: newMessagePreference});
    }
}

function handleUnarchiveChat(updateUserMessagePreferences: any, user: User | undefined, id?: string) {
  if (id && user?.id && user?.messagePreferences) {
    const newMessagePreference: UserMessagePreferences = {
      ...user.messagePreferences,
      archivedIds: (user.messagePreferences.archivedIds || []).filter(oldId => oldId !== id)
    };
    updateUserMessagePreferences({ userId: user.id, messagePreference: newMessagePreference });
  }
}

function handleDeleteChat(updateUserMessagePreferences: any, user: User | undefined, id?: string) {
  if (id && user?.id && user?.messagePreferences) {
    const date = new Date();
    const newDeletedChatInfo: deletedChatInfo = { id: id, date: date };

    const newDeletedChats = (user.messagePreferences.deleted || []).filter(info => info.id !== id);
    const newChatPreference: UserMessagePreferences = {
      ...user.messagePreferences,
      deleted: [...newDeletedChats, newDeletedChatInfo]
    };

    updateUserMessagePreferences({ userId: user.id, messagePreference: newChatPreference });
  }
}


export const chatPreferences: ChatPreferenceOptionsProps[] = [
  {
    id: "archive",
    label: "ARCHIVE_CHAT",
    title: 'archive_chat',
    dialogText: 'archive_chat_questions',
    onHandleAction: (updateUserMessagePreferences, user, id)=>{ handleArchiveChat(updateUserMessagePreferences, user, id)},
  },
  {
    id: "unarchive",
    label: "UNARCHIVE_CHAT",
    title: "unarchive_chat",
    dialogText: "unarchive_chat_questions",
    onHandleAction: (updateUserMessagePreferences, user, id)=>{ handleUnarchiveChat(updateUserMessagePreferences, user, id)},
  },
  {
    id: "delete",
    label: "DELETE_CHAT",
    title: "delete_chat",
    dialogText: "delete_chat_questions",
    onHandleAction: (updateUserMessagePreferences, user, id)=>{ handleDeleteChat(updateUserMessagePreferences, user, id)},
  },
];
