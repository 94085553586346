import {
  Workout,
  Exercise,
  Equipment,
  GeneralEntity,
  ProgramTemplate,
  Meal,
  Grocery,
  NutritionPlanTemplate,
  Tutorial
} from "myfitworld-model";
import interfaceFactory from "./dataInterfaceFactory";

export const equipmentApi = interfaceFactory<Equipment>('equipment');
export const workoutApi = interfaceFactory<Workout>('workouts');
export const programTemplateApi = interfaceFactory<ProgramTemplate>('programs');
export const exercisesApi = interfaceFactory<Exercise>('exercises');
export const mealsApi = interfaceFactory<Meal>('meals');
export const groceriesApi = interfaceFactory<Grocery>('groceries');
export const planTemplateApi = interfaceFactory<NutritionPlanTemplate>('nutrition_plans');
export const tutorialApi = interfaceFactory<Tutorial>('tutorials');

// enumerable entities

export const fitnessActivitiesApi = interfaceFactory<GeneralEntity>('fitnessActivities');
export const muscleGroupsApi = interfaceFactory<GeneralEntity>('muscleGroups');
export const goalsApi = interfaceFactory<GeneralEntity>('goals');
export const motorSkillsApi = interfaceFactory<GeneralEntity>('motorSkills');
export const sportsApi = interfaceFactory<GeneralEntity>('sports');
export const healthIssuesApi = interfaceFactory<GeneralEntity>('healthIssues');
export const expertisesApi = interfaceFactory<GeneralEntity>('expertises');
export const counterProductiveForApi = interfaceFactory<GeneralEntity>('counterProductiveFor');
export const mealTypesApi = interfaceFactory<GeneralEntity>('mealTypes');
export const goodForApi = interfaceFactory<GeneralEntity>('goodFor');
export const dietApi = interfaceFactory<GeneralEntity>('diet');
export const timePreparationApi = interfaceFactory<GeneralEntity>('timePreparation');
//export const totalKcalApi = interfaceFactory<GeneralEntity>('totalKcal');
export const categoryApi = interfaceFactory<GeneralEntity>('category');
export const glycemicIndexRangeApi = interfaceFactory<GeneralEntity>('glycemic_index_range');
export const glycemicLoadRangeApi = interfaceFactory<GeneralEntity>('glycemic_load_range');
export const micronutrientApi = interfaceFactory<GeneralEntity>('micronutrient');
export const durationInWeeksApi = interfaceFactory<GeneralEntity>('durationInWeeks');
export const durationConfigurationApi = interfaceFactory<GeneralEntity>('durationConfiguration');