import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import get from 'lodash/get';
import {Organization} from "myfitworld-model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      marginBottom: 0,
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectOrganizationField = ({
  label,
  options,
  value,
  onChange,
  required = false,
} : {
  label: string,
  options: Array<Organization>,
  value: string|undefined,
  onChange: ( value: Organization|undefined) => void,
  required?: boolean,
}) => {
  const classes = useStyles();
  return (
    <FormControl size="small" variant="outlined" className={classes.formControl} required={required}>
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        value={value || ''}
        MenuProps={MenuProps}
        onChange={e => onChange(options.find(item => item.id === (e as any).target.value as string)) }
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {get(option, 'name')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectOrganizationField;
