import React, {CSSProperties} from 'react';
import {Box, Chip, Typography} from '@material-ui/core';
import {Equipment} from "myfitworld-model";
import MFWChip from "../../../components/MFWChip";
import BaseContentListItem from "../../content/BaseContentListItem";
import getLocalizedProperty from '../../../utils/getLocalizedProperty';
import { useIntl } from 'react-intl';
import pageMessages from '../../messages';

const EquipmentListItem = (
  {item, onSelect, style}:
    { item: Equipment, onSelect: (event: React.MouseEvent) => void, disabled?: boolean, style?: CSSProperties }
) => {
  const {formatMessage, locale} = useIntl();
  const tags = [
    [item.incline, formatMessage(pageMessages.incline)],
    [item.speed, formatMessage(pageMessages.speed)],
    [item.weight, formatMessage(pageMessages.weight)],
    [item.rpm, formatMessage(pageMessages.rpm)],
    [item.pace, formatMessage(pageMessages.pace)],
    [item.color, formatMessage(pageMessages.color)],
    [item.distance, formatMessage(pageMessages.distance)],
    [item.floors, formatMessage(pageMessages.floors)]
    
  ].filter(tag => tag[0]).map(tag => tag[1])

  return (
    <BaseContentListItem onSelect={onSelect} style={style}>
      <Box display="flex" flexDirection="column">
        <MFWChip organizationId={item.organizationId}/>
        <Box display="flex" flexDirection="row" alignItems="center">
          {/*@ts-ignore: */}
          <Typography variant="h5">{getLocalizedProperty(locale, item.name) === undefined || getLocalizedProperty(locale, item.name) === "" ? item.name.en : getLocalizedProperty(locale, item.name)}</Typography>
          <Box display="flex" alignItems="center" flexWrap="nowrap" ml={0.5}>
            {tags.map((tag, i) => (
                <Box key={i} m={.25}>
                  <Chip
                    key={i}
                    size='small'
                    label={tag}
                  />
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>
    </BaseContentListItem>
  );
};

export default EquipmentListItem;
