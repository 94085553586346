export function sanitizeInput<T>(data: T): Partial<T> {
  if (typeof data === 'object' && data !== null && !Array.isArray(data) && !(data instanceof Date)) {
    return (Object.keys(data) as (keyof T)[]).filter(key => data[key] !== undefined).reduce(
      (acc: Partial<T>, curr: (keyof T)): Partial<T> => {
        return ({...acc, [curr]: sanitizeInput(data[curr])})
      }, {})
  } else {
    return data;
  }
}
