import ContentFilterValues from '../model/ContentFilter';
import isString from 'lodash/isString';

/**
 * Order is important, as it prioritizes more specific filters first.
 * E.g. if user selected a healthIssue and a sport, we can only actually filter
 * with `array-contains` for one of those and must filter the second field on the client.
 *
 * It’s better to use the healthIssue for `array-contains` as it will likely return a
 * smaller result set.
 */
const keys = ['healthIssues', 'motorSkills', 'muscleGroups', 'sports', 'equipment', 'muscleRegime', 'archived'];

/**
 * Sends an `array-contains` request with the first filter encountered.
 */
const queryBuilder = (firestoreQuery: any, filter: ContentFilterValues) => {
  for (let key of keys) {
    const valueKey = key as 'sports' | 'muscleGroups' | 'motorSkills' | 'healthIssues' | 'equipment' | 'muscleRegime' | 'archived';

    if (valueKey === 'archived' && filter[valueKey]) {
      firestoreQuery = firestoreQuery.where(valueKey, '==', filter[valueKey]);
      return firestoreQuery;
  } else if (isString(filter[valueKey]) && (filter[valueKey] as string).length > 0) {
      if (valueKey === 'muscleRegime') {
        firestoreQuery = firestoreQuery.where(valueKey, '==', filter[valueKey]);
      } else {
        firestoreQuery = firestoreQuery.where(valueKey, 'array-contains', filter[valueKey]);
      }
      return firestoreQuery;
    }
  }

  return firestoreQuery;
};

export default queryBuilder;
