import { UserProgram } from "myfitworld-model";
import { firestore } from "../firebase";
import { sanitizeInput } from "./sanitizeInput";
import { createClientWorkoutInProgram } from "./assignedWorkoutsApi";


export const fetchClientProgram = async (userId: string, path: 'users' | 'invitations', programId: string) => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedPrograms`).doc(programId); 
      const programDocument = await ref.get();
      const programData = programDocument.data();
      
      return {
        ...programData,
        id: programDocument.id
      } as UserProgram;
      
    } catch (error) {
      throw error;
    }
  }
  
export const createUserProgram = async (program: UserProgram, userId: string, path: 'users' | 'invitations') => {
    const workouts = program.workouts || [];
    delete program.workouts;

    let programId: string;
    let workoutIds: Array<string> = [];

    try {
        const ref = firestore.collection(`/${path}/${userId}/assignedPrograms`);
        const newProgramDocument = await ref.add({ ...sanitizeInput(program) });
        programId = newProgramDocument.id;

        if (workouts.length > 0) {
            for (let workout of workouts) {
                workout.parentId = programId;
                //w.isSingleWorkout = true;
                const workoutId = await createClientWorkoutInProgram({userId, path, workout, programId});
                workoutIds.push(workoutId);
            }
        }

        return {programId, workoutIds}
        
    } catch (error) {
        throw error;
    }
};

export const createSingleWorkoutsUserProgram = async (userId: string, path:'users' | 'invitations')=>{
    let program:UserProgram =  {
        title:"Single Workouts Program",
        defineExplicitTime: true,
        insertDate:'/',
        startDate:'/',
        endDate:'/',
        originalProgramId:'/',
        isActive: true,
        isSingleWorkouts:true
    }
    
    try{
        const ref = firestore.collection(`/${path}/${userId}/assignedPrograms`);
        const newProgramDocument = await ref.add({ ...sanitizeInput(program) });
        const programId = newProgramDocument.id;

        return programId;
    } catch( error){
        throw error;
    }

}

export const getIdForSingleWorkoutsProgram = async (userId: string, path: 'users' | 'invitations') => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedPrograms`);
      const querySnapshot = await ref.where('isSingleWorkouts', '==', true).get();
      if (!querySnapshot.empty) {
        let programId = querySnapshot.docs[0].id;
        return programId;
      } else {
        let programId: string = await createSingleWorkoutsUserProgram(userId, path);
        return programId;
      }
    } catch (error) {
      throw error;
    }
}

export const deleteProgram = async (userId: string, path: 'users' | 'invitations', programId: string) => {
    try {
      
      const ref = firestore.collection(`/${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts`);

      const batch = firestore.batch();
      const querySnapshot = await ref.where('finished', '==', false).get();
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
  
      const newSnapshot = await ref.get();
      if(newSnapshot.size===0){
        await firestore.collection(`/${path}/${userId}/assignedPrograms`).doc(programId).delete();
      }
  
    } catch (error) {
      throw error;
    }
}
  