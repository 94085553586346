import React, {useEffect, useState} from "react";
import {Activity, User} from "myfitworld-model";
import {Avatar, Box, Button, IconButton, makeStyles, Typography} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import usersApi from "../../api/usersApi";
import format from "date-fns/format";
import { CakeOutlined } from "@material-ui/icons";
import {useMfwThemeProvider} from "myfitworld-utils";
import { useIntl } from "react-intl";
import chatMessages from "./messages";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
    width: "100%",
  },
  avatar: {
    height: 32,
    width: 32,
  },
  image: {
    cursor: "pointer",
    height: "auto",
    maxWidth: "100%",
    width: 380,
  },
  systemMessageRoot: {
    display: "flex",
    width: "100%",
  },
  bubbleContainer: {
    width: "100%",
  },
  systemMessageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderWidth: "1px",
    borderColor: theme.palette.primary.dark,
    borderStyle: "solid",
    borderRadius: 4,
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  flexColumn: {
    width: "100%",
  },
  dateText: {
    marginBottom: theme.spacing(1),
  },
  messageText: {
    color: "#CDCDCD",
  },
  viewDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  viewDetails: {
    color: "#CDCDCD",
    marginRight: 13,
  },
  systemMessageHeading: {
    fontSize: 18,
    color: "#CDCDCD",
  },
  iconVerticalFix: {
    paddingTop: 2,
  },
}));

const BirthdayNotificationView = ({ activity, onSendHappyBirthday}: Props) => {
  const classes = useStyles();
  const theme = useMfwThemeProvider();
  const { formatMessage } = useIntl();
  

  const [client, setClient] = useState<User>();
  useEffect(() => {
    usersApi([])
      .get(activity.threadId)
      .then((res) => setClient(res));
  }, [activity.threadId]);

  useEffect(()=>{
      if(!client?.fitnessProfile?.age){
          return ;
      }
  },[client])

  const sendHappyBirthday = () => {
    onSendHappyBirthday();
  }

  return (
    <Box border={`2px solid ${theme.mfwMuiWebTheme.palette.primary.light}`} padding='2%' marginTop='2%' borderRadius={5} style={{backgroundColor: !activity.read ? 'rbga(0,0,0,0.1)': ''}}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex' flexDirection='row'>
          <Avatar className={classes.avatar} src={client?.avatarURL}/>
          <Typography variant="h5" style={{marginLeft: 10, marginTop: 5, fontWeight: 'bold'}}>
            {client?.firstName + " " +client?.lastName}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row'>
            <CakeOutlined color="inherit"/>
            <Typography variant="h5" style={{marginLeft: 5, marginTop: 5}}>{formatMessage(chatMessages.birthday_notification_title)}</Typography>
        </Box>
      </Box>
      <Box mt={1} className={classes.bubbleContainer}>
        <Box className={classes.flexColumn}>
          <Typography className={classes.dateText}>{formatMessage(chatMessages.client_birthday_today)}</Typography>
          <Typography className={classes.dateText}>{format(activity.createdAt, "Y-MM-dd HH:mm")}</Typography>
          <Box className={classes.viewDetailsContainer}>
            <Button variant="text" color="primary" onClick={sendHappyBirthday} endIcon={<ArrowForwardIosIcon />}>
            {formatMessage(chatMessages.birthday_greeting_card)}
            </Button>
          </Box>
        </Box>
      </Box>   
    </Box>
  );
};

export default BirthdayNotificationView;

interface Props {
    activity: Activity;
    onSendHappyBirthday: () => void;
  }
