import React, { memo, useMemo } from "react";
import { Icon } from '../../components';
import { Calendar as CalendarIcon } from '../../icons'
import { useMfwThemeProvider } from "myfitworld-utils";


const Calendar = () => {
  const strokeColor = useMfwThemeProvider().mfwMuiWebTheme.palette.text.primary;

  const styes = useMemo(() => {
    return { stroke: strokeColor, fill: 'none' }
  }, [strokeColor])

  return (
    <Icon icon={CalendarIcon} style={styes} />
  );
};

export default memo(Calendar);
