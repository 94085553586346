import React, { CSSProperties } from 'react';
import {Box, LinearProgress} from '@material-ui/core';
import {useStoreState} from "pullstate";
import globalState from "../globalState";

const styles: { root: CSSProperties } = {
  root: {
    height: '12px',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
};

const TopBarLoadingIndicator = () => {
  const toastQueue = useStoreState(globalState, s => s.globalLoadingQueue);
  if (toastQueue.length === 0) {
    return null;
  } else {
    return <Box style={styles.root}><LinearProgress color="primary" /></Box>;
  }
};

export default TopBarLoadingIndicator;
