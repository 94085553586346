import React, { useState, useContext, useRef, ChangeEvent, useMemo } from "react";
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  makeStyles,
  Theme,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { firestore } from "../../firebase";
import globalState, { GlobalState } from "../../globalState";
import UserContext from "../../contexts/UserContext";
import { Alert } from "@material-ui/lab";
import { navigate } from "@reach/router";
import { Role } from "myfitworld-model";
import firebase from "firebase";
import { useMfwThemeProvider } from "myfitworld-utils";
import { buildUrl, configMainRoutesConsts } from "../../config/route";
import { useIntl, FormattedMessage } from "react-intl";
import scheduleMessages from "./messages";
import globalMessages from "../../messages";
import { callDeleteAccount, callResendVerificationEmail } from "../../api/customAuthApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = (mfwTheme: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      dialogContent: {
        padding: 10,
      },
      centerText: {
        textAlign: "center",
      },
      loadingBox: {
        padding: 30,
      },
      loadingText: {
        textAlign: "center",
        marginTop: 5,
      },
      titleText: {
        textAlign: "center",
        marginTop: 10,
      },
      digitInput: {
        width: 50,
        marginRight: 5,
      },
      confirmButton: {
        marginTop: 15,
        marginLeft: 60,
      },
      resendContainer: {
        maxWidth: 400,
        margin: "16px auto",
      },
      resendText: {
        marginBottom: 8,
        textAlign: "center",
        fontWeight: 500,
      },
      italicSmaller: {
        fontStyle: "italic",
        fontSize: Number(theme.typography.body2.fontSize) * 0.8,
        marginTop: 10,
      },
      resendButton: {
        minWidth: 200,
      },
      loadingSpinner: {
        marginLeft: 5,
      },
      registrationLink: {
        textDecoration: "underline",
        fontWeight: "bold",
        cursor: "pointer",
      },
      randomNumberBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: 20,
        alignItems: 'center'
      },
      randomNumberButtonsBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 20
      },
      cancelButton: {
        marginRight: 30,
      },
      handleSupportEmail: {
        color: mfwTheme.mfwMuiWebTheme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "underline",
        fontWeight: "bold",
      },
    })
  );

const NON_CLIENT_ROLES = new Set([Role.SuperAdmin, Role.Admin, Role.Trainer, Role.AssistantTrainer]);

const VerificationDialog = () => {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const theme = useMfwThemeProvider();
  const [wrongCodeMessage, setWrongCodeMessage] = useState(false);
  const [firstDigit, setFirstDigit] = useState<string>();
  const [secondDigit, setSecondDigit] = useState<string>();
  const [thirdDigit, setThirdDigit] = useState<string>();
  const [fourthDigit, setFourthDigit] = useState<string>();
  const [repeatRegistration, setRepeatRegistration] = useState(false);
  const [enteredRandomNumber, setEnteredRandomNumber] = useState("");
  const [wrongRandomNumberMessage, setWrongRandomNumberMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const randomNumber = useMemo(() => Math.round(Math.random() * 100000), [repeatRegistration]);
  const isClient = useMemo(() => {
    if (!user?.organizations) return true;
    return !user.organizations.some((org) => NON_CLIENT_ROLES.has(org.role));
  }, [user]);
  const isOpened = (user && !user?.isVerified) || false;
  const [resendCooldown, setResendCooldown] = useState(0);
  const [resendError, setResendError] = useState("");
  const [isResending, setIsResending] = useState(false);

  const classes = useStyles(theme)();

  const redirectToProfile = localStorage.getItem("rebrandSubscription") || "";

  React.useEffect(() => {
    const currentTime = Date.now();
    //@ts-ignore
    const verificationTime = user?.verificationCodeSentAt || 0;
    const cooldownDuration = 60 * 1000; // 60sec

    if (currentTime - verificationTime < cooldownDuration) {
      const remainingTime = cooldownDuration - (currentTime - verificationTime);
      setResendCooldown(Math.floor(remainingTime / 1000));
    } else {
      setResendCooldown(0);
    }
    //@ts-ignore
  }, [user?.verificationCodeSentAt]);

  React.useEffect(() => {
    if (resendCooldown > 0) {
      const interval = setInterval(() => {
        setResendCooldown((current) => {
          if (current <= 1) {
            clearInterval(interval);
            return 0;
          }
          return current - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendCooldown]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setWrongCodeMessage(false);
    
    if (firstDigit && secondDigit && thirdDigit && fourthDigit) {
      const verificationCode = firstDigit.toString() + secondDigit.toString() + thirdDigit.toString() + fourthDigit.toString();
      user?.verificationCode && user.verificationCode === verificationCode ? successVerification() : unsuccessVerification();

      if (redirectToProfile) navigate('/profile');
    }
  };

  const successVerification = () => {
    firestore.collection("users").doc(user?.id).update({ isVerified: true });
    firestore
      .collection("invitations")
      .where("email", "==", user?.email)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          doc.ref
            .update({
              acceptedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            })
            .catch((err) => console.error(`Error acceptedOn in invitation`, err));
        });
      });

    globalState.update((state: GlobalState) => {
      state.toastQueue.push({
        message: formatMessage(scheduleMessages.account_successfully_confirmed),
        severity: "success",
      });
    });
  };

  const unsuccessVerification = () => {
    setWrongCodeMessage(true);
  };

  const isCorrectRandomNumber = () => {
    if (randomNumber === +enteredRandomNumber) {
      return true;
    } else {
      setWrongRandomNumberMessage(formatMessage(scheduleMessages.wrong_number));
      return false;
    }
  };

  const registerAgain = async () => {
    const language = user?.language;
    if (isCorrectRandomNumber()) {
      setLoader(true);
      callDeleteAccount("repeat_registration")
        .then((e) => {
          setLoader(false);
          navigate(
            buildUrl(
              isClient ? configMainRoutesConsts.createUserAccount : configMainRoutesConsts.trainerOrganizationLoginPage,
              [language || "en"]
            )
          );
        })
        .catch((e) => {
          console.log("error : ", e);
        });
    }
  };

  const handleInputChange = (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value;
    if (input.length === 1 && index < inputRefs.length - 1 && inputRefs[index + 1].current) {
      inputRefs[index + 1].current?.focus();
    } else if(input.length === 0 && index > 0 && index <= inputRefs.length - 1 && inputRefs[index - 1].current) {
      inputRefs[index - 1].current?.focus();
    }
  };

  const handleResendVerification = async () => {
    try {
      setResendError("");
      setIsResending(true);
      await callResendVerificationEmail();
      setResendCooldown(60); // 1min

      globalState.update((state: GlobalState) => {
        state.toastQueue.push({
          message: formatMessage(scheduleMessages.verification_email_resent),
          severity: "success",
        });
      });
    } catch (error) {
      setResendError(formatMessage(scheduleMessages.error_resending_verification));
      console.error("Error resending verification:", error);
    } finally {
      setIsResending(false);
    }
  };

  const handleEmailClick = () => {
    navigator.clipboard.writeText(theme.theme.whitelabel.supportEmail).then(() => {
      globalState.update((state: GlobalState) => {
        state.toastQueue.push({
          message: "Email copied to clipboard!",
          severity: "success",
        });
      });
    });
  };

  return (
    <Dialog onClose={() => {}} open={isOpened} maxWidth="sm" fullWidth disableBackdropClick>
      {!loader && (
        <DialogTitle id="simple-dialog-title">
          <Typography variant="h3">
            {repeatRegistration
              ? formatMessage(scheduleMessages.repeat_registration)
              : formatMessage(scheduleMessages.account_confirmation)}
          </Typography>
        </DialogTitle>
      )}
      {repeatRegistration ? (
        !loader ? (
          <DialogContent className={classes.dialogContent}>
            <DialogContentText className={classes.centerText}>
              {formatMessage(scheduleMessages.repeat_registration_text)}
              <br />
              {formatMessage(scheduleMessages.random_number_text, { randomNumber: randomNumber })}
            </DialogContentText>
            <Box className={classes.randomNumberBox}>
              <Typography color="error">{wrongRandomNumberMessage}</Typography>
              <TextField
                style={{width: 200}}
                value={enteredRandomNumber}
                onChange={(event) => {
                  setEnteredRandomNumber(event.target.value);
                }}
              />
              <Box className={classes.randomNumberButtonsBox}>
                <Button
                  onClick={() => setRepeatRegistration(false)}
                  className={classes.cancelButton}
                  color="secondary"
                  variant="outlined"
                >
                  {formatMessage(globalMessages.cancel)}
                </Button>
                <Button onClick={registerAgain} color="primary" variant="outlined" autoFocus>
                  {formatMessage(globalMessages.confirm)}
                </Button>
              </Box>
            </Box>
          </DialogContent>
        ) : (
          <Box className={classes.loadingBox}>
            <LoadingSpinner />
            <Typography variant="h4" color="primary" className={classes.loadingText}>
              {formatMessage(scheduleMessages.deleting_account)}
            </Typography>
          </Box>
        )
      ) : (
        <DialogContent className={classes.dialogContent}>
          {wrongCodeMessage && <Alert severity="error">{formatMessage(scheduleMessages.wrong_code_try_again)}</Alert>}
          <Typography variant="h5" className={classes.titleText}>
            <span style={{ color: theme.mfwMuiWebTheme.palette.primary.main }}>{theme.theme.whitelabel.name} </span>
            {formatMessage(scheduleMessages.account_successfully_confirmed)}&nbsp;
            {formatMessage(
              scheduleMessages.it_is_necessary_to_enter_the_verification_code_that_was_sent_to_the_email
            )}{" "}
            <span style={{ color: theme.mfwMuiWebTheme.palette.primary.main }}>{user?.email}</span>{" "}
            {formatMessage(scheduleMessages.to_confirm_the_registration)}
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <form onSubmit={onSubmit}>
              <Box flexDirection="row" display="flex" justifyContent="center" marginTop={2}>
                <TextField
                  className={classes.digitInput}
                  inputRef={inputRefs[0]}
                  variant="outlined"
                  name="firstDigit"
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(0, e);
                    setFirstDigit(e.target.value);
                  }}
                  autoFocus
                />
                <TextField
                  className={classes.digitInput}
                  inputRef={inputRefs[1]}
                  variant="outlined"
                  name="secondDigit"
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(1, e);
                    setSecondDigit(e.target.value);
                  }}
                />
                <TextField
                  className={classes.digitInput}
                  inputRef={inputRefs[2]}
                  variant="outlined"
                  name="thirdDigit"
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(2, e);
                    setThirdDigit(e.target.value);
                  }}
                />
                <TextField
                  className={classes.digitInput}
                  inputRef={inputRefs[3]}
                  variant="outlined"
                  name="fourthDigit"
                  autoComplete="off"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) => {
                    handleInputChange(3, e);
                    setFourthDigit(e.target.value);
                  }}
                />
              </Box>
              <Button
                className={classes.confirmButton}
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                {formatMessage(scheduleMessages.confirm)}
              </Button>
            </form>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop={2}
            border={1}
            borderColor="divider"
            borderRadius={4}
            padding={2}
            className={classes.resendContainer}
          >
            {resendError && <Typography color="error">{resendError}</Typography>}
            <Typography variant="body2" color="textSecondary" className={classes.resendText}>
              {formatMessage(scheduleMessages.did_not_receive_verification_email)}
            </Typography>
            <Button
              className={classes.resendButton}
              onClick={handleResendVerification}
              disabled={resendCooldown > 0 || isResending}
              color="primary"
              variant="outlined"
              size="small"
            >
              {isResending ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  {formatMessage(scheduleMessages.resend_verification_email)}
                  <CircularProgress size={15} className={classes.loadingSpinner} />
                </Box>
              ) : resendCooldown > 0 ? (
                `${formatMessage(scheduleMessages.resend_verification_email)} (${Math.floor(resendCooldown / 60)}:${(
                  resendCooldown % 60
                )
                  .toString()
                  .padStart(2, "0")})`
              ) : (
                formatMessage(scheduleMessages.click_to_resend)
              )}
            </Button>
            {resendCooldown > 0 && (
              <>
                <span>{formatMessage(scheduleMessages.code_sent_to)} </span>
                <span style={{ color: theme.mfwMuiWebTheme.palette.primary.main }}>{user?.email}</span>
              </>
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              className={`${classes.resendText} ${classes.italicSmaller}`}
            >
              <FormattedMessage
                {...scheduleMessages.still_having_problems_did_not_receive_verification_email}
                values={{
                  styledEmail: (
                    <span className={classes.handleSupportEmail} onClick={handleEmailClick}>
                      {theme.theme.whitelabel.supportEmail}
                    </span>
                  ),
                }}
              />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Typography style={{ textAlign: "center" }}>
              {formatMessage(scheduleMessages.if_the_displayed_email_is_not_correct_you_can)}{" "}
              <a
                onClick={() => setRepeatRegistration(true)}
                className={classes.registrationLink}
                style={{ color: theme.mfwMuiWebTheme.palette.primary.main }}
              >
                {formatMessage(scheduleMessages.repeat_the_registration_here)}
              </a>
            </Typography>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default VerificationDialog;
