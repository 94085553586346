import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    my_schedule: {
        id: 'My Schedule',
        defaultMessage: 'My Schedule'
    },
    schedule_error: {
        id: 'schedule_page.schedule_error',
        defaultMessage: 'There was an error loading your schedule. Please refresh the page. If the problem persists, please contact support.'
    },
    clients_count_0_alert: {
        id: 'schedule_page.clients_count_0_alert',
        defaultMessage: 'Your account is setup, but you don’t have any clients, yet! Go to the'
    },
    clients_section_link: {
        id: 'schedule_page.clients_section_link',
        defaultMessage: ' clients section '
    },
    to_invite_and_menage: {
        id: 'schedule_page.to_invite_and_menage',
        defaultMessage: 'to invite and manage your clients.'
    },
    all_done_for_now: {
        id: 'schedule_page.all_done_for_now',
        defaultMessage: 'All done for now? Currently, none of your clients have any upcoming workouts. Head over to the'
    },
    to_manage_their_workout: {
        id: 'schedule_page.to_manage_their_workout',
        defaultMessage: 'to manage their workout flow.'
    },
    repeat_the_registration_here: {
        id: 'login_page.verification_dialog.repeat_the_registration_here',
        defaultMessage: ' repeat the registration here.'
    },
    account_successfully_confirmed: {
        id: 'messages.account_successfully_confirmed',
        defaultMessage: 'Account successfully confirmed!'
    },
    account_confirmation: {
        id: 'login_page.account_confirmation',
        defaultMessage: 'Account Confirmation'
    },
    wrong_code_try_again: {
        id: 'messages.wrong_code_try_again',
        defaultMessage: 'Wrong code. Try again!'
    },
    it_is_necessary_to_enter_the_verification_code_that_was_sent_to_the_email: {
        id: 'login_page.verification_dialog.it_is_necessary_to_enter_the_verification_code_that_was_sent_to_the_email',
        defaultMessage: 'It is necessary to enter the verification code that was sent to the email '
    },
    account_successfully_created: {
        id: 'login_page.verification_dialog.account_successfully_created',
        defaultMessage: ' account successfully created!'
    },
    to_confirm_the_registration: {
        id: 'login_page.verification_dialog.to_confirm_the_registration',
        defaultMessage: ' to confirm the registration'
    },
    confirm: {
        id: 'schedule_page.confirm',
        defaultMessage: 'Confirm'
    },
    if_the_displayed_email_is_not_correct_you_can: {
        id: 'login_page.verification_dialog.if_the_displayed_email_is_not_correct_you_can',
        defaultMessage: 'If the displayed email is not correct, you can'
    },
    confirm_delete: {
        id: 'schedule_page.confirm_delete',
        defaultMessage: 'Are you sure you want to delete this workout?'
    },
    repeat_registration: {
        id: 'verification.repeat_registration',
        defaultMessage: 'Repeat registration'
    },
    repeat_registration_text: {
        id: 'verification.repeat_registration_text',
        defaultMessage: 'If you repeat registration, all your data will be permanently removed. You will no longer have access to workouts, history, statistics, messages, and all the data you currently have.'
    },
    random_number_text: {
        id: 'verification.random_number_text',
        defaultMessage: 'To confirm the account deletion, in the field below enter the number {randomNumber}'
    },
    wrong_number: {
        id: 'verification.wrong_number',
        defaultMessage: 'Wrong entered number'
    },
    deleting_account: {
        id: 'verification.deleting_account',
        defaultMessage: 'Deleting account'
    },
    resend_verification_email: {
        id: 'schedule.verification.resend_verification_email',
        defaultMessage: 'Resend verification email'
    },
    verification_email_resent: {
        id: 'schedule.verification.verification_email_resent',
        defaultMessage: 'Verification email has been resent'
    },
    error_resending_verification: {
        id: 'schedule.verification.error_resending_verification',
        defaultMessage: 'Error resending verification email. Please try again later.'
    },
    did_not_receive_verification_email: {
        id: 'schedule.verification.did_not_receive_verification_email',
        defaultMessage: 'Did not receive the verification email?'
    },
    click_to_resend: {
        id: 'schedule.verification.click_to_resend',
        defaultMessage: 'Click here to resend'
    },
    still_having_problems_did_not_receive_verification_email: {
        id: 'schedule.verification.still_having_problems_did_not_receive_verification_email',
        defaultMessage: 'Still having problems? Write to {styledEmail} and expect a very quick response.'
    },
    code_sent_to: {
        id: 'schedule.verification.code_sent_to',
        defaultMessage: ' Code sent to :'
    }
    
})

export default messages;
