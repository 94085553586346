import { useCallback} from "react";
import { useMutation } from "../base";
import { Role } from "myfitworld-model";
import globalState, { GlobalState } from "../../globalState";
import { updateUserOrganizations } from "../../api/usersApi";
import { useIntl } from "react-intl";
import organizationsMessages from "./messages";

export interface OrgInUser{
  id: string;
  role: Role;
  archived?: boolean | undefined;
}

function useUpdateOrganizationClient() {
  const {formatMessage} = useIntl();
  
  const updateOrganizationClientQuery = useCallback(async ({userId, organizations}:{userId: string; organizations: OrgInUser[]})=>{
    try {
      return updateUserOrganizations({id: userId, organizations: organizations} );
    } catch (error) {
      globalState.update((state:GlobalState)=>{
        state.toastQueue.push({
          message: formatMessage(organizationsMessages.error_updating_Organizations_In_User),
          severity:"error"
        })
      }) 
      throw error
    }
  }, []);


  const { status, error, isLoading } = useMutation(updateOrganizationClientQuery);
  return {
    status,
    error,
    isLoading,
    updateOrganizationClientQuery,
  };
}
export default useUpdateOrganizationClient;
