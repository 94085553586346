import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    configurable_distance: {
        id: 'equipment_file.equipment_form.configurable_distance',
        defaultMessage: 'Configurable Distance'
    },
    configurable_floors: {
        id: 'equipment_file.equipment_form.configurable_floors',
        defaultMessage: 'Configurable Floors'
    },
    configurable_incline: {
        id: 'equipment_file.equipment_form.configurable_incline',
        defaultMessage: 'Configurable Incline'
    },
    configurable_speed: {
        id: 'equipment_file.equipment_form.configurable_speed',
        defaultMessage: 'Configurable Speed'
    },
    configurable_weight: {
        id: 'equipment_file.equipment_form.configurable_weight',
        defaultMessage: 'Configurable Weight'
    },
    configurable_rpm: {
        id: 'equipment_file.equipment_form.configurable_rpm',
        defaultMessage: 'Configurable RPM'
    },
    configurable_pace: {
        id: 'equipment_file.equipment_form.configurable_pace',
        defaultMessage: 'Configurable Pace'
    },
    configurable_power: {
        id: 'equipment_file.equipment_form.configurable_power',
        defaultMessage: 'Configurable Power'
    },
    configurable_level: {
        id: 'equipment_file.equipment_form.configurable_level',
        defaultMessage: 'Configurable Level'
    },
    configurable_color: {
        id: 'equipment_file.equipment_form.configurable_color',
        defaultMessage: 'Configurable Color'
    },
    describe_exercise_equipment_preferences: {
        id: 'equipment_file.equipment_form.describe_exercise_equipment_preferences',
        defaultMessage: 'Describe exercise equipment preferences'
    },
    add_the_first_equipment_to_get_started: {
        id: 'equipment_file.equipment_form.add_the_first_equipment_to_get_started',
        defaultMessage: 'Add the first equipment to get started.'
    }
})
export default messages;