import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    please_enter_the_title_of_the_exercise_in_english: {
        id: 'messages.please_enter_the_title_of_the_exercise_in_english',
        defaultMessage: 'Fill in the name in the required field - \"EN\"'
    },
    you_have_to_upload_video_file_or_youtube_link: {
        id: 'messages.you_have_to_upload_video_file_or_youtube_link',
        defaultMessage: 'You have to upload video file or YouTube link'
    },
    edit_grocery: {
        id: 'button.edit_grocery',
        defaultMessage: 'Edit Grocery'
    },
    edit_tutorial: {
        id: 'button.edit_tutorial',
        defaultMessage: 'Edit Tutorial'
    },
    edit_exercise: {
        id: 'button.edit_exercise',
        defaultMessage: 'Edit Exercise'
    },
    edit_equipment: {
        id: 'equipment_file.edit_equipment',
        defaultMessage: 'Edit Equipment'
    },
    edit_activity: {
        id: 'equipment_file.edit_activity',
        defaultMessage: 'Edit Activity'
    },
    edit_muscle_group: {
        id: 'equipment_file.edit_muscle_group',
        defaultMessage: 'Edit Muscle Group'
    },
    edit_goal: {
        id: 'equipment_file.edit_goal',
        defaultMessage: 'Edit Goal'
    },
    edit_motor_skill: {
        id: 'equipment_file.edit_motor_skill',
        defaultMessage: 'Edit Motor Skill'
    },
    edit_sport: {
        id: 'equipment_file.edit_sport',
        defaultMessage: 'Edit Sport'
    },
    edit_health_issue: {
        id: 'equipment_file.edit_health_issue',
        defaultMessage: 'Edit Health Issue'
    },
    edit_expertise: {
        id: 'equipment_file.edit_expertise',
        defaultMessage: 'Edit Expertise'
    },
    edit_duration: {
        id: 'equipment_file.edit_duration',
        defaultMessage: 'Edit Duration'
    },
    create_new_exercise: {
        id: 'button.create_new_exercise',
        defaultMessage: 'Create New Exercise'
    },
    create_new_grocery: {
        id: 'button.create_new_grocery',
        defaultMessage: 'Create New Grocery'
    },
    create_new_workout: {
        id: 'button.create_new_workout',
        defaultMessage: 'Create New Workout'
    },
    create_new_program: {
        id: 'button.create_new_program',
        defaultMessage: 'Create New Program'
    },
    create_new_plan: {
        id: 'button.create_new_plan',
        defaultMessage: 'Create New Plan'
    },
    create_new_equipment: {
        id: 'equipment_file.create_new_equipment',
        defaultMessage: 'Create New Equipment'
    },
    create_new_activity: {
        id: 'equipment_file.create_new_activity',
        defaultMessage: 'Create New Activity'
    },
    create_new_muscle_group: {
        id: 'equipment_file.create_new_muscle_group',
        defaultMessage: 'Create New Muscle Group'
    },
    create_new_goal: {
        id: 'equipment_file.create_new_goal',
        defaultMessage: 'Create New Goal'
    },
    create_new_motor_skill: {
        id: 'equipment_file.create_new_motor_skill',
        defaultMessage: 'Create New Motor Skill'
    },
    create_new_sport: {
        id: 'equipment_file.create_new_sport',
        defaultMessage: 'Create New Sport'
    },
    create_new_health_issue: {
        id: 'equipment_file.create_new_health_issue',
        defaultMessage: 'Create New Health Issue'
    },
    create_new_expertise: {
        id: 'equipment_file.create_new_expertise',
        defaultMessage: 'Create New Expertise'
    },
    create_new_meal: {
        id: 'nutrition_plan.create_new_meal',
        defaultMessage: 'Create New Meal'
    },
    create_tutorial: {
        id: 'tutor.create_tutorial',
        defaultMessage: 'Create Tutorial'
    },
    create_new_duration: {
        id: 'equipment_file.create_new_duration',
        defaultMessage: 'Create New Duration'
    },
    unarchive_grocery: {
        id: 'nutrition_plan.unarchive_grocery',
        defaultMessage: 'Unarchive Grocery'
    },
    archive_grocery: {
        id: 'nutrition_plan.archive_grocery',
        defaultMessage: 'Archive Grocery'
    },
    unarchive_grocery_question: {
        id: 'nutrition_plan.unarchive_grocery_question',
        defaultMessage: 'Do you really want to unarchive this grocery?'
    },
    archive_grocery_question: {
        id: 'nutrition_plan.archive_grocery_question',
        defaultMessage: 'Do you really want to archive this grocery?'
    },
    unarchive_exercise: {
        id: 'exercise.unarchive_exercise',
        defaultMessage: 'Unarchive Exercise'
    },
    archive_exercise: {
        id: 'exercise.archive_exercise',
        defaultMessage: 'Archive Exercise'
    },
    unarchive_exercise_question: {
        id: 'exercise.unarchive_exercise_question',
        defaultMessage: 'Do you really want to unarchive this exercise?'
    },
    archive_exercise_question: {
        id: 'exercise.archive_exercise_question',
        defaultMessage: 'Do you really want to archive this exercise?'
    },
    create_and_save_your_first_exercise: {
        id: 'button.create_and_save_your_first_exercise',
        defaultMessage: 'Create and save your first exercise!'
    },
    create_and_save_your_first_workout: {
        id: 'button.create_and_save_your_first_workout',
        defaultMessage: 'Create and save your first workout!'
    },
    create_and_save_your_first_program: {
        id: 'button.create_and_save_your_first_program',
        defaultMessage: 'Create and save your first program!'
    },
    create_and_save_your_first_grocery: {
        id: 'button.create_and_save_your_first_grocery',
        defaultMessage: 'Create and save your first grocery!'
    },
    create_and_save_your_first_meal: {
        id: 'button.create_and_save_your_first_meal',
        defaultMessage: 'Create and save your first meal!'
    },
    create_and_save_your_first_plan: {
        id: 'button.create_and_save_your_first_plan',
        defaultMessage: 'Create and save your first plan!'
    },
    create_and_save_your_first_tutorial: {
        id: 'button.create_and_save_your_first_tutorial',
        defaultMessage: 'Create and save your first tutorial!'
    },
    exercise_title: {
        id: 'exercise.exercise_title',
        defaultMessage: 'Exercise'
    },
    workout_title: {
        id: 'workout.content_workout.workout_title',
        defaultMessage: 'Workout'
    },
    program_title: {
        id: 'program.program_title',
        defaultMessage: 'Program'
    },
    grocery: {
        id: 'nutrition_plan.grocery',
        defaultMessage: 'Grocery'
    },
    meal: {
        id: 'nutrition_plan.meal',
        defaultMessage: 'Meal'
    },
    nutrition_plan: {
        id: 'nutrition_plan.nutrition_plan',
        defaultMessage: 'Nutrition Plan'
    },
    equipment: {
        id: 'Equipment',
        defaultMessage: 'Equipmentn'
    },
    tutorial_videos: {
        id: 'tutor.tutorial_video',
        defaultMessage: 'Tutorial Videos'
    },

})

export default messages;
