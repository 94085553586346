export const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;


export const acceptWLClientRequest = async ({
  userId,
  organizationId,
  userEmail
}: {
  userId:string; 
  organizationId: string;
  userEmail: string
}) => {
  try {
    const response = await fetch(`${FUNCTIONS_BASE_URL}/clientRequests/accept-request`, {
        method: "POST",
        body:  JSON.stringify({
            userId: userId,
            orgId: organizationId,
            userEmail: userEmail
        }),
        headers: {
            'Content-Type': 'application/json'
        }
      });
      return response;
    } catch (error) {
        throw error;
    }
};


export const rejectWLClientRequest = async ({
    userId,
    organizationId,
    userEmail
  }: {
    userId:string; 
    organizationId: string;
    userEmail: string
  }) => {
    try {
  
      const response = await fetch(`${FUNCTIONS_BASE_URL}/clientRequests/reject-request`, {
          method: "POST",
          body: JSON.stringify({
            userId: userId,
            orgId: organizationId,
            userEmail: userEmail
          }),
          headers: {
            'Content-Type': 'application/json'
            }
        });
        return response;
      } catch (error) {
          throw error;
      }
  };

