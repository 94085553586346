import React, {Fragment} from 'react';
import EntityForm, {EntityFormProps} from "../../../entity/EntityForm";
import {Equipment} from "myfitworld-model";
import LocalizedTextField from "../../../components/LocalizedTextField";
import useOrganization from "../../../hooks/useOrganization";
import { useIntl } from 'react-intl';
import globalMessages from '../../../messages';

const GeneralEntityForm = (props: EntityFormProps<Equipment>) => {
  const { organizationId } = useOrganization();
  const disableForm = props.defaultState?.id ? props.defaultState?.organizationId !== organizationId : false;
  const {formatMessage} = useIntl()

  return (
    <EntityForm<Equipment> {...props} disabled={disableForm}>
      {
        ({register}) => (
          <Fragment>
            <LocalizedTextField
              name='name'
              inputRef={register}
              margin='normal'
              label={formatMessage(globalMessages.name)}
              type='text'
              autoFocus
              fullWidth
              required
              disabled={disableForm}
            />
          </Fragment>
        )
      }
    </EntityForm>
  );

};


export default GeneralEntityForm;
