import {ApiInterface, createProviderFactory, getProviderFactory, updateProviderFactory} from "./dataInterfaceFactory";
import {Organization, Resource, Role, User} from "myfitworld-model";
import {firestore} from "../firebase";
import firebase from "firebase";
import OrganizationType from "myfitworld-model/dist/enums/OrganizationType";
import {CorpOrganization} from 'myfitworld-model';

const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

function rootListProviderFactory<T extends Resource>(endpoint: string): (_: string) => Promise<Array<T>> {
  return async () => {
    return new Promise((resolve) => {
      firestore
        .collection(endpoint)
        .where('type', '==', OrganizationType.Gym)
        .get()
        .then(querySnapshot => {
          const data: Array<T> = [];
          querySnapshot.forEach(snapshot => {
            data.push({
              id: snapshot.id,
              ...snapshot.data()
            } as T);
          });
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          resolve([])
        })
    })
  }
}

const organizationsApi: ApiInterface<Organization> = {
  create: createProviderFactory<Organization>("organizations", {type: OrganizationType.Gym}),
  update: updateProviderFactory<Organization>("organizations"),
  list: rootListProviderFactory<Organization>("organizations"),
  get: getProviderFactory<Organization>("organizations"),
  getCorpUsers: getProviderFactory<Organization>("organizations")
};

export default organizationsApi;

export const getRoleForUser = (userId: string, organization:Organization) => {
  return organization.admins?.find(item => item === userId) ? Role.Admin : Role.Trainer;
}

export const getOrganizationByName = (
  orgName: string
): Promise<Organization | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('organizations').where('nameLowerCase', '==', orgName).get().then((snapshot) => {
      snapshot.empty ? resolve(null) : resolve({id: snapshot.docs[0].id, ...snapshot.docs[0].data()} as Organization);
    }).catch((err) => {
      reject(null);
    })
  });

export const getOrganization = async (organizationId: string): Promise<Organization | undefined| void> => {
  return new Promise((resolve) => {
    firestore
      .collection('organizations')
      .doc(organizationId)
      .get()
      .then(querySnapshot => {
        resolve({id: querySnapshot.id, ...querySnapshot.data()} as Organization);
      })
      .catch(() => {
        resolve();
      })
  })
}

export const getCorpOrganization = async (corpId: string): Promise<CorpOrganization> => {
  return new Promise((resolve) => {
    firestore
      .collection('corporateOrg')
      .doc(corpId)
      .get()
      .then(querySnapshot => {
        resolve({...querySnapshot.data()} as CorpOrganization);
      })
      .catch((err) => {
        console.log(err);
      })
  })
}

// export interface CorpCodes {
//   claimed: boolean,
//   codeId: string,
//   corpId: string,
// }
//
export const getCorpOrganizationClients = (corpId: string, userType: number):Promise<User[]>  => {
  return new Promise((resolve, reject) => {
    let users: User[] = [];
    let ref = firestore
        .collection('users')
        .where('corpId', '==', corpId);
    if(userType === 2){
      ref = ref.where('premiumStatus', '==', true);
    } 

    ref.get().then((snapshot) => {
      snapshot.docs.map(doc => {
        users.push(doc.data() as User);
      })
      resolve(users);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export const toggleArchiveClient = async (userId:string, orgId: string, action:string, trainerId: string, allowContent: boolean) => {
  const rSendObj = {
    orgId : orgId,
    action: action,
    userId: userId,
    trainerId: trainerId,
    allowContent: allowContent
  };

  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/organizations/toggle-archived`, {
  method: 'PUT',
  body: JSON.stringify(rSendObj),
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token ? 'Bearer ' + token : ''
  }
});
if(response.status === 200){return true;}
return false;
};

export const deleteOrganization = (orgID:string):Promise<any> => new Promise(async(resolve,reject)=>{
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/deleteFirestoreData/organization/${orgID}`, {
    method: 'DELETE',
    body: "",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return resolve(response);
})

export const setLastOnlineTimeOrganization = async (organizationId: string): Promise<Organization | undefined| void> => {
  return new Promise((resolve) => {
    firestore.doc(`/organizations/${organizationId}`).update({lastAdminPageLoadTime: new Date()})
  })
}

export const unlinkClientFromAnOrganization = async (clientId:string) : Promise<any> =>new Promise(async(resolve,reject)=>{
      const token = await firebase.auth().currentUser?.getIdToken();
      const response = await fetch(`${FUNCTIONS_BASE_URL}/organizations/remove-client/${clientId}`, {
      method: 'GET',
      headers: {
        'Authorization': token ? 'Bearer ' + token : ''
      }
    });
    return resolve(response);
})
