import React, { memo, useMemo } from "react";
import { Icon } from '../../components';
import {Library as LibraryIcon} from '../../icons'
import { useMfwThemeProvider } from "myfitworld-utils";
import { fi } from "date-fns/locale";


const Content = () => {
  const fillColor = useMfwThemeProvider().mfwMuiWebTheme.palette.text.primary;

  const styes = useMemo(() => {
    return { stroke: 'none', fill: fillColor }
  }, [fillColor])

  return (
    <Icon icon={LibraryIcon} style={styes} />
  );
};

export default memo(Content);
