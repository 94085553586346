import {createContext} from 'react';
import { UserProviderState } from '../providers/UserProvider';


const UserContext = createContext<UserProviderState & {
  setOrganizationOverride: (organizationId: string | undefined) => void, enterApplication?: boolean
}>({
  user: null,
  loading: false,
  needsInvitation: false,
  enterApplication: false,
  userRole:{
    isOrgAdmin : false,
    isTrainerWithinOrg : false
  },
  setOrganizationOverride: () => {
  }
});

export default UserContext;
