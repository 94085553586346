import React, {CSSProperties} from 'react';
import {Box, Typography} from '@material-ui/core';
import {GeneralEntity} from "myfitworld-model";
import MFWChip from "../../../components/MFWChip";
import getLocalizedProperty from "../../../utils/getLocalizedProperty";
import BaseContentListItem from "../../content/BaseContentListItem";
import { useIntl } from 'react-intl';

const GeneralEntityListItem = (
  {item, onSelect, disabled, style}:
    { item: GeneralEntity, onSelect: (event: React.MouseEvent) => void, disabled: boolean, style?: CSSProperties }
) => {

  const {locale} = useIntl();
  const action = (event: React.MouseEvent) => {
    if (!disabled) {
      return onSelect && onSelect(event);
    } else {
      return null;
    }
  };
  
  return (
    <BaseContentListItem onSelect={action} style={style} height={62}>
      <Box>
        <MFWChip organizationId={item.organizationId}/>
        <Typography variant="h5">{getLocalizedProperty(locale, item.name) === undefined ||  getLocalizedProperty(locale, item.name) === "" ? item.name.en : getLocalizedProperty(locale, item.name)}</Typography>
      </Box>
    </BaseContentListItem>
  );
};

export default GeneralEntityListItem;
