import get from "lodash/get";
import { LocalizedProperty } from "myfitworld-model";
import { useIntl } from "react-intl";

export default function (property?: LocalizedProperty): string {
    const { locale } = useIntl();
    const langprop = property ? (get(property, locale) as string)?(get(property, locale) as string): "" : "";
    const description = property ? (langprop || get(property, 'en') as string) : "";
    
    // Replace newline characters with <br> tags and tabs with nbsp
    return  description.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  }