import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    activity: {
        id: 'equipment_file.activity',
        defaultMessage: 'Activity'
    },
    muscle_group: {
        id: 'equipment_file.muscle_group',
        defaultMessage: 'Muscle Group'
    },
    goal: {
        id: 'equipment_file.goal',
        defaultMessage: 'Goal'
    },
    motor_skill: {
        id: 'equipment_file.motor_skill',
        defaultMessage: 'Motor Skill'
    },
    sport: {
        id: 'equipment_file.sport',
        defaultMessage: 'Sport'
    },
    health_issue: {
        id: 'equipment_file.health_issue',
        defaultMessage: 'Health Issue'
    },
    expertise: {
        id: 'equipment_file.expertise',
        defaultMessage: 'Expertise'
    },
    duration: {
        id: 'equipment_file.duration',
        defaultMessage: 'Duration'
    },
    equipment: {
        id: 'equipment_file.equipment',
        defaultMessage: 'Equipment'
    },
    motor_skills: {
        id: 'equipment_file.motor_skills',
        defaultMessage: 'Motor Skills'
    },
    health_issues: {
        id: 'equipment_file.health_issues',
        defaultMessage: 'Health Issues'
    },
    muscle_groups: {
        id: 'equipment_file.muscle_groups',
        defaultMessage: 'Muscle Groups'
    },
    goals: {
        id: 'equipment_file.goals',
        defaultMessage: 'Goals'
    },
    sport_list: {
        id: 'equipment_file.sport_list',
        defaultMessage: 'Sport List'
    },
})
export default messages;