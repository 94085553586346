import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    access_is_invitation_only: {
        id: 'access_is_invitation_only',
        defaultMessage: 'Access is invitation only, please check with your admin to share you the invitation'
    },
    show_wl_originals_filter_title: {
        id: 'messages.switches.show_wl_originals_filter_title',
        defaultMessage: '{name} Content'
    },
    org_content_title: {
        id: 'messages.switches.org_content_title',
        defaultMessage: 'Organization Content'
    },
    premium_reports: {
        id: 'premium_reports',
        defaultMessage: 'Premium Reports'
    },
    premium_code: {
        id: 'premium_code',
        defaultMessage: 'Premium Code'
    },
    premium_subscription_type: {
        id: 'premium_subscription_type',
        defaultMessage: 'Subscription Type'
    },
    from: {
        id: 'statistics.from',
        defaultMessage: 'FROM'
    },
    to: {
        id: 'statistics.to',
        defaultMessage: 'TO'
    },
    search: {
        id: 'statistics.search',
        defaultMessage: 'SEARCH'
    },
    monthly: {
        id: 'subscription.monthly',
        defaultMessage: 'MONTHLY'
    },
    yearly: {
        id: 'subscription.yearly',
        defaultMessage: 'YEARLY'
    },
    active: {
        id: 'premium.active',
        defaultMessage: 'ACTIVE'
    },
    unactive: {
        id: 'premium.unactive',
        defaultMessage: 'UNACTIVE'
    },
    type: {
        id: 'organization.type',
        defaultMessage: 'Type'
    },
  rebrand_discount_badge_title: {
    id: 'rebrand.rebrand_discount_badge_title',
    defaultMessage: 'Billing & Discounts:'
  },
  rebrand_discount_badge_text1: {
    id: 'rebrand.rebrand_discount_badge_text1',
    defaultMessage: `You are currently on the MFW FREE plan. The first 2 Clients are $$FREE$$, with NO time limitations. Here are the PRICING dynamics for $$Standard$$, and $$Rebrand$$ users:\n`
  },
  rebrand_discount_badge_free: {
    id: 'rebrand.rebrand_discount_badge_free',
    defaultMessage: 'FREE'
  },
  rebrand_discount_badge_standard: {
    id: 'rebrand.rebrand_discount_badge_standard',
    defaultMessage: 'Standard'
  },
  rebrand_discount_badge_rebrand: {
    id: 'rebrand.rebrand_discount_badge_rebrand',
    defaultMessage: 'Rebrand*'
  },
  rebrand_standard_monthly_price: {
    id: 'rebrand.rebrand_standard_monthly_price',
    defaultMessage: '$$Standard$$ (monthly prices):\n'
  },
  rebrand_rebrand_monthly_price: {
    id: 'rebrand.rebrand_rebrand_monthly_price',
    defaultMessage: '$$Rebrand*$$ (monthly prices):\n'
  },
  rebrand_stripe_payment: {
    id: 'rebrand.rebrand_stripe_payment',
    defaultMessage: '* We use Stripe as a payment processor, a leader among payment processors, worldwide. Pricing is prorated daily, which means that you are charged just for the clients that are in the $$Active$$ and $$Pending lists$$. You are not charged for the  $$Archived$$ Clients. More details in our Terms of Use.\n'
  },
  rebrand_discount_exclusive_pricing: {
    id: 'rebrand.rebrand_discount_exclusive_pricing',
    defaultMessage: 'Here is your $$Re-Brand Exclusive Pricing:$$'
  },
  rebrand_discount_badge_note: {
    id: 'rebrand.rebrand_discount_badge_note',
    defaultMessage: '* To become a $$Rebrand$$ User of the App, please visit your $$Profile$$ Section, follow the steps with explanations and instructions, and enjoy your fully branded MFW Web & Mobile App!\n'
  },
  rebrand_discount_badge_0_2: {
    id: 'rebrand.rebrand_discount_badge_0_2',
    defaultMessage: '0-2 clients: $$FREE$$, always. Even when you go over 3 clients, the first 2 will be free of charge.'
  },
  rebrand_discount_badge_3_14: {
    id: 'rebrand.rebrand_discount_badge_3_14',
    defaultMessage: '3-14 clients:  2.4 EUR / Client / Monthly  -20%'
  },
  rebrand_discount_badge_15_29: {
    id: 'rebrand.rebrand_discount_badge_15_29',
    defaultMessage: '15-29 clients: 2.1 EUR / Client / Monthly -30%'
  },
  rebrand_discount_badge_30_49: {
    id: 'rebrand.rebrand_discount_badge_30_49',
    defaultMessage: '30-49 clients: 1.5 EUR / Client / Monthly -50%'
  },
  rebrand_discount_badge_50_99: {
    id: 'rebrand.rebrand_discount_badge_50_99',
    defaultMessage: '50-99 clients: 1.2 EUR / Client / Monthly -60%'
  },
  rebrand_discount_badge_100: {
    id: 'rebrand.rebrand_discount_badge_100',
    defaultMessage: '100+ clients: 0.9 EUR / Client / Monthly  -70%'
  },
  standard_discount_badge_0_2: {
    id: 'rebrand.standard_discount_badge_0_2',
    defaultMessage: '0-2 clients: FREE, always. Even when you go over 3 clients, the first 2 will be free of charge.'
  },
  standard_discount_badge_3_14: {
    id: 'rebrand.standard_discount_badge_3_14',
    defaultMessage: '3-14 clients: 3 EUR / Client / Monthly'
  },
  standard_discount_badge_15_29: {
    id: 'rebrand.standard_discount_badge_15_29',
    defaultMessage: '15-29 clients: 2.7 EUR / Client / Monthly  -10%'
  },
  standard_discount_badge_30_49: {
    id: 'rebrand.standard_discount_badge_30_49',
    defaultMessage: '30-49 clients: 2.4 EUR / Client / Monthly  -20%'
  },
  standard_discount_badge_50_99: {
    id: 'rebrand.standard_discount_badge_50_99',
    defaultMessage: '50-99 clients: 2.1 EUR / Client / Monthly  -30%'
  },
  standard_discount_badge_100: {
    id: 'rebrand.standard_discount_badge_100',
    defaultMessage: '100+ clients: 1.5 EUR / Client / Monthly   -50%'
  },
  rebrand_pricing_details: {
    id: 'rebrand.rebrand_pricing_details',
    defaultMessage: 'MFW Pricing Details:'
  },
  rebrand_pricing_explanation: {
    id: 'rebrand.rebrand_pricing_explanation',
    defaultMessage: 'The first 2 Clients are $$FREE$$, with NO time limitations. Here are the PRICING dynamics for $$Standard$$, and $$Rebrand$$ users:'
  },
  rebrand_current_billing_discount: {
    id: 'rebrand.rebrand_current_billing_discount',
    defaultMessage: 'Your current billing discount is: '
  },
  rebrand_please_note: {
    id: 'rebrand.rebrand_please_note',
    defaultMessage: 'Please note that the discounts are related to our standard 3 EUR / Client / Monthly. '
  },
  rebrand_prices_applied: {
    id: 'rebrand.rebrand_prices_applied',
    defaultMessage: 'Prices are applied after onboarding the 3rd client, and further. '
  },
  rebrand_if_when_cancel_rebrand: {
    id: 'rebrand.rebrand_if_when_cancel_rebrand',
    defaultMessage: 'If / When you cancel the Rebrand Subscription, $$Standard$$ Pricing will be applied automatically:'
  },
  rebrand_purchase_lifetime_rebrand: {
    id: 'rebrand.rebrand_purchase_lifetime_rebrand',
    defaultMessage: 'If you purchase $$LIFETIME REBRAND, Rebrand$$ Exclusive Pricing will be applied for a lifetime.'
  },
  rebrand_manage_rebrand_subscription: {
    id: 'rebrand.rebrand_manage_rebrand_subscription',
    defaultMessage: `You can manage your $$Rebrand$$ subscription under your $$Profile$$ Section.`
  },
  rebrand_clients: {
    id: 'rebrand.rebrand_clients',
    defaultMessage: `Clients`
  },
  rebrand_no_discount: {
    id: 'rebrand.rebrand_no_discount',
    defaultMessage: 'No Discount'  
  }
})

export default messages;
