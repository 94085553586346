export type MobileDeviceType = 'android' | 'ios' | 'unknown';
export type BrowserType = 'Safari';

export function getMobileDeviceType(userAgent: string): MobileDeviceType {
  if (userAgent.match(/Android/i)) {
    return 'android';
  } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
    return 'ios';
  } else {
    return 'unknown';
  }
}
