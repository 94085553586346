import {Avatar} from "@material-ui/core";
import React, {CSSProperties} from "react";

const ProfileImage = ({src, initials, size, fontSize, mirror}: Props) => {
  return (
    <Avatar
      alt="Profile image"
      src={src ?? ""}
      style={{
        fontSize: fontSize ?? 50,
        width: size ?? 140,
        height: size ?? 140,
        // transform: mirror && src ? "scaleX(-1)" : "scaleX(1)"
      }}
    >
      {src ? null : initials}
    </Avatar>
  );
};

export default ProfileImage;

interface Props {
  src: string | undefined | null;
  initials: string;
  size?: number;
  fontSize?: number;
  style?: CSSProperties,
  mirror?: boolean
}
