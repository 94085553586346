import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import { User } from "myfitworld-model";
import useUpdateUserMessagePreferences from "../../hooks/Users/useUpdateArchivedUserChats";
import { useUserProvider } from "../../providers/UserProvider";
import { useIntl } from "react-intl";
import globalMessages from "../../messages";
import chatMessages from './messages';
export interface ChatPreferenceOptionsProps{
  id: string,
  label:string,
  title:string,
  dialogText: string,
  selectedUserId?:string,
  selectedUserName?:string,
  onHandleAction:(updateUserMessagePreferences:any,user?:User, id?:string)=>void;
}

const PreferenceChatDialog = ({isOpen, handleClose,chatPreferenceOptions}:{
    isOpen:boolean, 
    handleClose:()=>void;
    chatPreferenceOptions: ChatPreferenceOptionsProps
}) => {
  const {formatMessage} = useIntl();
  const {updateUserMessagePreferences: updateFunction, isLoading: isUpdatingMessagePreferances} = useUpdateUserMessagePreferences();
  const {user} = useUserProvider();
  console.log(chatPreferenceOptions);
  return (
    <Dialog
      aria-labelledby="chat-dialog"
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClick={(event) => {event.stopPropagation(); }}
    >
          <DialogTitle>{formatMessage((chatMessages as any)[chatPreferenceOptions.title])} : <b>{chatPreferenceOptions.selectedUserName}</b></DialogTitle> 
          <DialogContent>
            {formatMessage((chatMessages as any)[chatPreferenceOptions.dialogText])}
          </DialogContent>
        <DialogActions>
            <Button onClick={()=>{
              chatPreferenceOptions.onHandleAction(updateFunction, user || undefined, chatPreferenceOptions.selectedUserId)
              handleClose();
              }}>{formatMessage(globalMessages.confirmed)}</Button>
            <Button
              onClick={handleClose}
            >{formatMessage(globalMessages.cancel)}</Button>
        </DialogActions>
    </Dialog>
  );
};

export default PreferenceChatDialog;
