import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import LoadingSpinner from "./LoadingSpinner";

const SimpleDialog = ({
  title,
  text,
  open,
  onClose,
  onConfirm,
  onConfirmLabel,
  onCancel,
  onCancelLabel,
  children,
  loader,
  thirdButtonText,
  thirdButtonOnClick,
}: {
  title: string;
  text: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onConfirmLabel: string;
  onCancel: () => void;
  onCancelLabel: string;
  children?: React.ReactElement;
  loader?: boolean;
  thirdButtonText?: string;
  thirdButtonOnClick?: () => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title.toUpperCase()}</DialogTitle>
      <Box>
        {loader ? <LoadingSpinner marginTop={1}></LoadingSpinner> : null}
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {children}
        </DialogContent>
      </Box>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" disabled={loader ? true : false}>
          {onConfirmLabel}
        </Button>
        {thirdButtonText && (
          <Button
            onClick={() => {
              thirdButtonOnClick && thirdButtonOnClick();
            }}
            color="primary"
            disabled={loader ? true : false}
          >
            {thirdButtonText}
          </Button>
        )}
        <Button onClick={onCancel} color="secondary" disabled={loader ? true : false}>
          {onCancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
