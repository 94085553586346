import { useLocation, useNavigate } from "@reach/router";
import {useMemo} from "react";
import queryString from "querystring";
import { useIntl } from "react-intl";
import hookMessages from './messages';

const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {formatMessage} = useIntl();
  const navigation = useMemo(() => async (route: string, state?: { [key: string]: any }, skipChecks?: boolean) => {
    const queries = queryString.parse(location.search.replace('?', ''));

    // @ts-ignore
    if ((queries.form !== 'open' && !location?.state?.formDirty) || skipChecks === true) {
      await navigate(route, state ? { state} : undefined);
      return true;
    } else {
      const windowConfirmMessage = formatMessage(hookMessages.open_form)
      const prompt = window.confirm(
        `${windowConfirmMessage}`
      );
      if (prompt) {
        await navigate(route, state ? { state} : undefined);
        return true;
      }
    }
    return false;
  }, [location, navigate]);

  return {
    navigation,
  };
};

export default useNavigation;
