import React, {MutableRefObject, PropsWithChildren} from 'react';
import {Box, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";

export const CRUDListWrapper = ({children}: { children: React.ReactNode }) =>
  <div style={{display: 'flex'}}>
    {children}
  </div>;

export type CRUDListLeftContainerRef =
  MutableRefObject<HTMLDivElement | null>
  | ((instance: HTMLDivElement | null) => void)
  | null;


export const CRUDListLeftContainer = React.forwardRef(({children}: PropsWithChildren<any>, ref: CRUDListLeftContainerRef) =>
  <div ref={ref} style={{display: 'flex', flex: 1}}>
    {children}
  </div>);

export const CRUDListRightContainer = (
  {children}:
    { children: React.ReactNode }
) =>
  <div style={{
    display: 'flex',
    flex: 1,
  }}>
    {children}
  </div>;

export const CRUDListHeader = (
  {title, ctaLabel, disabled, onClick}:
    { title: string, ctaLabel: string, disabled: boolean, onClick: (event: React.MouseEvent) => void }
) =>
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="baseline"
    mb={2}
    ml={1}
  >
    <Typography
      variant='h4'
      component='h1'
      color='textSecondary'
    >
      {title}
    </Typography>

    {!disabled &&
    <Button
      color='primary'
      onClick={onClick}
      disabled={disabled}
    >
      {ctaLabel}
    </Button>
    }
  </Box>;
