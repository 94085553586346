import { useCallback} from "react";
import { useMutation } from "../base";
import globalState, { GlobalState } from "../../globalState";
import { updateUserMessagePreferences } from "../../api/usersApi";
import { useIntl } from "react-intl";
import messages from "./messages";
import { UserMessagePreferences } from "myfitworld-model/dist/firestoreCollectionTypes/User";


function useUpdateUserMessagePreferences(){

  const {formatMessage} = useIntl();
  const updateUserMessagePreferencesQuery = useCallback(async ({userId, messagePreference}:{userId?: string; messagePreference?: UserMessagePreferences})=>{
    try {
      if(userId && messagePreference)
        return updateUserMessagePreferences(userId, messagePreference );
    } catch (error) {
      globalState.update((state:GlobalState)=>{
        state.toastQueue.push({
          message: formatMessage(messages.error_updating_archived_id),
          severity:"error"
        })
      }) 
      throw error
    }
  }, []);


  const { status, error, isLoading } = useMutation(updateUserMessagePreferencesQuery);
  
  return {
    status,
    error,
    isLoading,
    updateUserMessagePreferences: updateUserMessagePreferencesQuery,
  };
}
export default useUpdateUserMessagePreferences;
