import {Typography, Box, Theme, createStyles} from "@material-ui/core";
import React from "react";
import StarIcon from '@material-ui/icons/Star';
import {makeStyles} from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { useMfwThemeProvider } from "myfitworld-utils";
import { useIntl } from "react-intl";
import componentMessages from './messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(.5),
      marginBottom: theme.spacing(.5),
    },
  }),
);

export default ({ organizationId, withGrid = true, orgContent }: { organizationId: string, withGrid?: boolean, orgContent?: boolean }) => {
  const classes = useStyles();
  const theme = useMfwThemeProvider();
  const {formatMessage} = useIntl()
  const isMFWNet = useSelector((state: any) => state.toggleShowPublic);

  if (organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID) {
    if (withGrid) {
      return (
        <Box display="flex" alignItems="center">
          <StarIcon className={classes.icon} fontSize="inherit"/>
          <Typography variant="caption" color="primary">{formatMessage(componentMessages.show_wl_originals_filter_title, {name: theme.theme.whitelabel.name})}</Typography>
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center">
          <StarIcon fontSize="inherit"/>
          <Typography variant="caption" color="primary">{formatMessage(componentMessages.show_wl_originals_filter_title, {name: 'MyFitWorld'})}</Typography>
        </Box>
      );
    }
  }

  {
    if (orgContent && !isMFWNet) {
      return (
        <Box display="flex" alignItems="center">
          <StarIcon className={classes.icon} fontSize="inherit" color="secondary"/>
          <Typography variant="caption" color="secondary">{formatMessage(componentMessages.org_content_title)}</Typography>
        </Box>
      )
    }
  }

  return null;
}
