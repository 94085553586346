import React, { memo, useMemo } from "react";
import { Icon } from '../../components';
import { Gear as GearIcon } from '../../icons'
import { useMfwThemeProvider } from "myfitworld-utils";


const Gear = () => {
  const fillColor = useMfwThemeProvider().mfwMuiWebTheme.palette.text.primary;

  const styes = useMemo(() => {
    return { stroke: 'none', fill: fillColor }
  }, [fillColor])

  return (
    <Icon icon={GearIcon} style={styes} />
  );
};

export default memo(Gear);
