import { OrganizationUser, Role } from "myfitworld-model";
import { TrainerListItem } from "../../api/firebaseQueries/Organizations/trainers";
import { User } from "myfitworld-model/src";
import globalMessages from '../../messages';
import { MessageDescriptor } from "react-intl";
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from "react";

export interface UsersTableProps {
  roles: Role[];
  onClick?: (userId: string, user: OrganizationUser) => void;
  showRole: boolean;
  showWarnings?: boolean;
  disabled?: boolean;
  selectedTab?: any;
  setSelectedTab?: Dispatch<SetStateAction<number>>;
}
export interface RemoveUserDialogProps {
  delete: boolean;
  anonymize: boolean;
  orgUser: OrganizationUser | null;
  isClickOnRowEnabled: boolean;
  dialogLoader: boolean;
}

export interface DateTimeOptionsField{
  id: string;
  name: string[];
}

export interface DynamicDateTimeOptionsField {
  id: string;
  name: {
    en: string;
    sr: string;
    de: string;
    hr: string;
    es: string;
    ro: string;
    bg: string;
    ru: string;
  }
}

interface OptionsType {
  id: string;
  name: string[]
}

export const DateTimeOptions: DateTimeOptionsField[] = [
  { id: "ALL", name:["no_limit"] },
  { id: "DAYS_1", name: ["days_1" ]},
  { id: "DAYS_7", name: ["days_7" ]},
  { id: "DAYS_10", name: ["days_10" ]},
  { id: "DAYS_30", name: ["days_30" ]},
  { id: "DAYS_60", name: ["days_60" ]},
  { id: "DAYS_90", name: ["days_90"] },
];

export const StatusOptions: OptionsType[] = [
  { id: 'ALL', name: ['status_all'] },
  { id: 'FR', name: ['fr_free'] },
  { id: 'STD', name: ['std_standard'] },
  { id: 'RB', name: ['rb_rebrand'] },
  { id: 'PY', name: ['py_paying'] },
  { id: 'PRM', name: ['prm_premium'] },
  { id: 'GLD', name: ['gld_gold'] },
  { id: 'SLV', name: ['slv_silver'] },
  { id: 'PLT', name: ['plt_platinum'] },
];

export const QuantityOptions: OptionsType[] = [
  { id: 'ALL', name: ['status_all'] },
  { id: '0', name: ['clients_0'] },
  { id: '1-2', name: ['clients_1_2'] },
  { id: '3-9', name: ['clients_3_9'] },
  { id: '10-19', name: ['clients_10_19'] },
  { id: '20-29', name: ['clients_20_29'] },
  { id: '30-39', name: ['clients_30_39'] },
  { id: '40-49', name: ['clients_40_49'] },
  { id: '50-79', name: ['clients_50_79'] },
  { id: '80-99', name: ['clients_80_99'] },
  { id: '100+', name: ['clients_100'] },
];

export const generateDateTimeOptions = (): DynamicDateTimeOptionsField[] => {
  const startYear = 2020;
  const startMonth = 10;
  const currentDate = new Date();

  const options: DynamicDateTimeOptionsField[] = [];
  options.push({
    id: "ALL",
    name: {
      en: "ALL",
      sr: "ALL",
      de: "ALL",
      hr: "ALL",
      es: "ALL",
      ro: "ALL",
      bg: "ALL",
      ru: "ALL",
    }
  });

  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();

  while (year > startYear || (year === startYear && month >= startMonth)) {
    const monthName = format(new Date(year, month, 1), 'MMMM yyyy');
    const monthKey = monthName.replace(" ", "_").toLowerCase();

    options.push({
      id: monthName.replace(" ", "_").toUpperCase(),
      name: {
        en: monthKey,
        sr: monthKey,
        de: monthKey,
        hr: monthKey,
        es: monthKey,
        ro: monthKey,
        bg: monthKey,
        ru: monthKey,
      }
    });

    month--;
    if (month < 0) {
      month = 11;
      year--;
    }
  }

  return options;
};

export const categorizeDate = (createdAt: Date): string => {
  const now = new Date();
  const diffInMs = now.getTime() - createdAt.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays < 1) {
    return "Today";
  } else if (diffInDays <= 1) {
    return "1 day";
  } else if (diffInDays <= 7) {
    return "7 days";
  } else if (diffInDays <= 10) {
    return "10 days";
  } else if (diffInDays <= 15) {
    return "15 days";
  } else if (diffInDays <= 30) {
    return "1 month";
  } else {
    const diffInMonths = Math.floor(diffInDays / 30);
    return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''}`;
  }
}

export const categorizeLastActiveDate = (lastActive: Date): string => {
  const now = new Date();
  const diffInMs = now.getTime() - lastActive.getTime();

  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''}`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''}`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''}`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''}`;
}

export function getOptionsWithTranslation(
  options: OptionsType[],
  { formatMessage }: { formatMessage: (prop: MessageDescriptor) => string }
) {
  return options.map((option) => ({
    id: option.id,
    name: {
      en: formatMessage((globalMessages as any)[option.name[0]]),
      sr: formatMessage((globalMessages as any)[option.name[0]]),
      de: formatMessage((globalMessages as any)[option.name[0]]),
      hr: formatMessage((globalMessages as any)[option.name[0]]),
      es: formatMessage((globalMessages as any)[option.name[0]]),
      ro: formatMessage((globalMessages as any)[option.name[0]]),
      bg: formatMessage((globalMessages as any)[option.name[0]]),
      ru: formatMessage((globalMessages as any)[option.name[0]]),
    },
  }));
}

export function getTrainerNames (trainerIds: string[] | undefined, allTrainerList: TrainerListItem[] | undefined | null){
  if(!trainerIds) return [];
  let trainerNames: string[] = [];
  allTrainerList?.map((trainer)=>{
    if (trainerIds.includes(trainer.id)){
      trainerNames.push(trainer.name);
    }
  })
  return trainerNames;
}

export function isAdmin(authUser: User | null){
  if(authUser){
    const currentOrganization = authUser?.currentOrganization;
    const organization = authUser?.organizations?.find((org)=>{
      if(org.id === currentOrganization) return org;
    })
    if(Role.Admin == organization?.role || Role.SuperAdmin == organization?.role || authUser.isSuperAdmin){
      return true
    } return false;
  } else return false;
}
