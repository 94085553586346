import { WorkoutDay } from "myfitworld-model";
import { firestore } from "../firebase";
import { WorkoutMinimal } from "myfitworld-model/src";
import { getIdForSingleWorkoutsProgram } from "./assignedProgramsApi";

export type WorkoutErrorType = "notFound" | "clientNotFound";
export class WorkoutError extends Error {
  private _type: WorkoutErrorType;

  constructor(message: string, type: WorkoutErrorType) {
    super(message);
    this._type = type;
  }
  get type() {
    return this._type;
  }
}

export const fetchClientWorkouts = async ({userId, path, startDate, endDate}:{
  userId: string;
  path:'users'|'invitations' 
  startDate: Date; 
  endDate: Date; 
}) => {
  try {
    const userDoc = await firestore.collection(path).doc(userId).get();

    if (!userDoc.exists) {
      throw new WorkoutError(`${path}/${userId} not found!`, "clientNotFound");
    }

    const assignedWorkouts = await firestore
      .collectionGroup("assignedWorkouts")
      .where("userId", "==", userId)
      .where("dateTime", ">=", path ==='users' ? startDate : startDate.toISOString())
      .where("dateTime", "<=", path ==='users' ? endDate : endDate.toISOString())
      .get();
    let workoutData = assignedWorkouts.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        dateTime:
          data.dateTime && data.dateTime.toDate ? data.dateTime.toDate().toJSON() : new Date(data.dateTime).toJSON(),
          // creating new Date only will cause timezone issues
          // this can be shortened by using: data?.dateTime?.toDate?.()?.toJSON() ?? new Date(data.dateTime).toJSON()
      } as WorkoutDay;
    });
    return workoutData;
  } catch (error) {
    throw error;
  }
};

export const fetchNewClientWorkouts = async ({userId,path, oldStartDate,oldEndDate,newStartDate,newEndDate}:{
  userId: string;
  path : "users" | "invitations",
  oldStartDate: Date;
  oldEndDate: Date;
  newStartDate: Date;
  newEndDate: Date;
}) => {
  // This function is aimed to be called on range change. This means that we already have some workouts
  // from the old start-end date and when whe change the range to new start-end date, we should just search
  // for the workouts between: old start date <-> new start date, old end date <-> new end date
  try {
    let baseQuery = firestore.collectionGroup("assignedWorkouts").where("userId", "==", userId);
    if (newStartDate < oldStartDate) {
      baseQuery = baseQuery
      .where("dateTime", "<", path === 'users' ? oldEndDate : oldStartDate.toISOString())
      .where("dateTime", ">=",path === 'users' ? newStartDate : newStartDate.toISOString());
    }

    if (newEndDate > oldEndDate) {
      baseQuery = baseQuery
      .where("dateTime", ">",  path === 'users' ? oldEndDate : oldEndDate.toISOString())
      .where("dateTime", "<=", path === 'users' ? newEndDate : newEndDate.toISOString());
    }
    const assignedWorkouts = await baseQuery.get();
    const workoutData = assignedWorkouts.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        dateTime:
          data.dateTime && data.dateTime.toDate ? data.dateTime.toDate().toJSON() : new Date(data.dateTime).toJSON(),
      } as WorkoutDay;
    });
    return workoutData;
  } catch (error) {
    throw error;
  }
};

export const deleteClientWorkout = async (userId: string,path: 'users'|'invitations', workoutId: string, programId: string) => {
  try {
    const userDoc = await firestore.collection(path).doc(userId).get();

    if (!userDoc.exists) {
      throw new WorkoutError(`${path}/${userId} not found!`, "clientNotFound");
    }

    await firestore
      .collection(`${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts/`)
      .doc(workoutId)
      .delete();
  } catch (error) {
    throw error;
  }
};

export const updateClientWorkout = async (
  userId: string,
  path: 'users' | 'invitations',
  workoutId: string,
  programId: string,
  updatedWorkoutMinimal: WorkoutMinimal
) => {
  try {
    await firestore.collection(`${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts/`).doc(workoutId).update({
      workout: updatedWorkoutMinimal,
    });
  } catch (error) {
    throw error;
  }
};

export const updateClientWorkoutDateTime = async ({userId, path, workoutId, programId, dateTime}:{
  userId: string;
  path: 'users' | 'invitations';
  workoutId: string;
  programId: string;
  dateTime: string;
}) => {
  try {
    const userDoc = await firestore.collection(path).doc(userId).get();

    if (!userDoc.exists) {
      throw new WorkoutError(`${path}/${userId} not found!`, "clientNotFound");
    }

    await firestore
      .collection(`${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts/`)
      .doc(workoutId)
      .update({
        dateTime: new Date(dateTime),
      });
  } catch (error) {
    throw error;
  }
};

export const updateClientWorkoutMarkAsDone = async ({userId, path, workoutId, programId, isFinished}:{
  userId: string;
  path:'users' | 'invitations';
  workoutId: string;
  programId: string;
  isFinished: boolean;
  }) => {
  try {
    const userDoc = await firestore.collection(path).doc(userId).get();

    if (!userDoc.exists) {
      throw new WorkoutError(`${path}/${userId} not found!`, "clientNotFound");
    }

    await firestore
      .collection(`${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts/`)
      .doc(workoutId)
      .update({
        finished: isFinished
      });
  } catch (error) {
    throw error;
  }
};
export const updateClientWorkoutMarkAsFirstInCycle = async ({args}:{ args:UpdateClientWorkoutMarkAsFirstInCycleArgs}) => {
  const {userId,path,workoutId,  programId, isFirstInCycle} = args;
  try {
    const userDoc = await firestore.collection(path).doc(userId).get();

    if (!userDoc.exists) {
      throw new WorkoutError(`${path}/${userId} not found!`, "clientNotFound");
    }

    await firestore
      .collection(`${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts/`)
      .doc(workoutId)
      .update({
        firstInCycle: isFirstInCycle
      });
  } catch (error) {
    throw error;
  }
};

export const createClientWorkoutInProgram = async ({userId, workout, programId, path}:{
  userId: string;
  path: string;
  workout: WorkoutDay;
  programId: string;
  }) => {
  const assignedWorkout:WorkoutDay = {
    ...workout,
    finished:false,
    userId:userId,
  }

  delete workout.id;
  try {
    const userDoc = await firestore.collection(path).doc(userId).get();

    if (!userDoc.exists) {
      throw new WorkoutError(`${path}/${userId} not found!`, "clientNotFound");
    }

    const newDoc = await firestore
      .collection(`${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts/`)
      .add(assignedWorkout);

      return newDoc.id; 
  } catch (error) {
    throw error;
  }
};

export const createClientWorkoutInSingleWorkoutsProgram = async (
  userId: string,
  workout: WorkoutDay,
  path: 'users' | 'invitations',
) => {

  const programId: string = await getIdForSingleWorkoutsProgram(userId, path);
  const assignedWorkout:WorkoutDay = {
    ...workout,
    finished:false,
    userId: userId,
    parentId: programId,
    isSingleWorkout: true
  };

  delete workout.id;
  try {
    const userDoc = await firestore.collection(path).doc(userId).get();

    if (!userDoc.exists) {
      throw new WorkoutError(`${path}/${userId} not found!`, "clientNotFound");
    }
    const newDoc = await firestore
      .collection(`${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts/`)
      .add(assignedWorkout);
 
    return {workoutId: newDoc.id, programId: programId}; 
  } catch (error) {
    throw error;
  }
};

export interface UpdateClientWorkoutMarkAsFirstInCycleArgs {
  userId: string;
  path:'users' | 'invitations';
  workoutId: string;
  programId: string;
  isFirstInCycle: boolean;
};