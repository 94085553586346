export const checkSuperCardNum = (num: number | null) => {
    if (num&&num.toString().length < 16) {
        return "Too short for a valid SuperKartica number" //#prevod (ostavili smo za sada ovako jer je problem prevesti, prevod za ovo je vec uradjen)
    } else {
        const arr = (num + '')
        .split('')
        .reverse()
        .map(x => parseInt(x));
        const lastDigit = arr.shift();
        let sum = arr.reduce(
        (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val)),
        0
        );
        if (lastDigit) {
        sum += lastDigit;
        }
        return sum % 10 === 0;
    }
  };