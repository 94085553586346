import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {Exercise} from "myfitworld-model";
import {firestore} from "../firebase";
import useOrganization from "../hooks/useOrganization";
import {filterByOrganization} from "../api/dataInterfaceFactory";
import clientSideContentFilter from "../api/clientSideContentFilter";
import ContentFilterValues from "../model/ContentFilter";
import { useIntl } from "react-intl";
import get from "lodash/get";
import { useMfwThemeProvider } from 'myfitworld-utils';


type OrderBy = {
  key: string,
  order: "asc" | "desc"
}

const ExerciseProviderContext = createContext<{
  exercises: Exercise[] | null,
  isLoading: boolean,
  setContentFilter?: (contentFilter: ContentFilterValues) => void,
  setOrderBy?: (orderBy: OrderBy) => void
}>({exercises: null, isLoading: false})

export default ({children}: PropsWithChildren<any>) => {
  const { locale } = useIntl()
  const [exercises, setExercises] = useState<Exercise[] | null>(null);

  const [contentFilter, setContentFilter] = useState<ContentFilterValues | undefined>();
  const [orderBy, setOrderBy] = useState<OrderBy>({key: `title.${locale}`, order: "asc"});
  const [isLoading, setIsLoading] = useState(false)

  const {organizationId} = useOrganization();
  const {theme} = useMfwThemeProvider();

  useEffect(() => {

    if (!organizationId) return;

    setIsLoading(true);

    let firebaseRef = filterByOrganization('exercises', organizationId, firestore.collection('exercises'));
    
    return firebaseRef.onSnapshot(documents => {
      const exercises = documents.docs.map(item => ({id: item.id, ...item.data()}) as Exercise)

      const data = contentFilter ? clientSideContentFilter(exercises, contentFilter, true, theme.orgId) : exercises
      setExercises(orderBy ? data.sort((l, r) => get(l, orderBy.key)?.localeCompare(get(r, orderBy.key))) : data)
      setIsLoading(false);
    }, error => {
      console.error('error fetching exercises', error);
      setExercises(null)
      setIsLoading(false);
    })

  }, [organizationId, contentFilter, orderBy])

  return <ExerciseProviderContext.Provider value={{exercises: exercises, isLoading, setContentFilter, setOrderBy}}>
    {children}
  </ExerciseProviderContext.Provider>
}


export const useExerciseProvider = () => {
  const context = useContext(ExerciseProviderContext);

  if (context === undefined) {
    throw new Error(
      'useExerciseProvider must be used within an ExerciseProvider'
    );
  }
  return context;
};
