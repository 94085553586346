import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Activity } from "myfitworld-model";
import { format } from "date-fns";
import {useMfwThemeProvider} from "myfitworld-utils";
import { useIntl } from "react-intl";
import chatMessages from './messages';

const useStyles = makeStyles((theme) => ({
    bubbleContainer: {
      width: "100%",
    },
    flexColumn: {
      width: "100%",
    },
    dateText: {
      marginBottom: theme.spacing(1),
    }
}));

const DeleteUserNotificationView = ({activity} : {activity: Activity}) => {
    const {formatMessage} = useIntl();
    const theme = useMfwThemeProvider();
    const classes = useStyles();
    
    return (
        <Box border={`2px solid ${theme.mfwMuiWebTheme.palette.primary.light}`} padding='2%' marginTop='2%' borderRadius={5} color={'red'}>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Box display='flex' flexDirection='row'>
                    <Typography variant="h5" style={{marginLeft: 5, marginTop: 5}}>{formatMessage(chatMessages.user_with_email)} {activity.email} {formatMessage(chatMessages.has_deleted_account)}</Typography>
                </Box>
            </Box>
            <Box mt={1} className={classes.bubbleContainer}>
                <Box className={classes.flexColumn}>
                    <Typography className={classes.dateText}>{format(activity.createdAt, "Y-MM-dd HH:mm")}</Typography>
                </Box>
            </Box>
        </Box>   
    )
};
export default DeleteUserNotificationView;
